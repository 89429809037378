import queryString from 'query-string';
import { IUser } from '../../service/user';
import { IAgency } from '../../service/agency';
import { IXmlFeedRealty } from '../../service/xml.feed';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { successNotify } from '../../helpers/createNotify';
import { IResponseListData } from '../../interfaces/common';
import type { UploadProps } from '~/src/service/realty.secondary.service';
import * as api from '~/src/service/realty.secondary.service';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import { IFilestore } from '~/src/service/filestore';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IMegasaleInfoMegasale, IMegaSaleRealty } from '../megasaleSlice';

export const sliceName = 'realtySecondary';

interface IRSSlice {
    items: IResponseListData<IRSModel>;
    itemsOut: number;
    item?: IRSModel;
    itemPhotos?: IFilestore[];
    mapItems?: IRSMapModel[];
    search?: IRSSearch;
    errors?: any;
    loading: boolean;
    navLink: string;
    baseLink: string;
    currentIndex: number;
}

const initialState = {
    items: {},
    itemsOut: null,
    mapItems: [],
    search: {
        sort: 'created_at_desc',
        limit: 25,
        page: 1,
        own: 'all',
    },
    loading: true,
    navLink: '/realty-secondary',
    baseLink: '/realty-secondary',
    errors: {},
    currentIndex: null,
};

const RSSlice: Slice<IRSSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IRSSlice,
            action: TAnyAction<IResponseListData<IRSModel>>,
        ) => {
            state.items = action.payload;
        },
        setAllOut: (state: IRSSlice, action: TAnyAction<number>) => {
            state.itemsOut = action.payload;
        },
        setMapItems: (state: IRSSlice, action: TAnyAction<IRSMapModel[]>) => {
            state.mapItems = action.payload;
        },
        set: (state: IRSSlice, action: TAnyAction<IRSModel>) => {
            state.item = action.payload;
            state.itemPhotos = state.item.photos;
        },
        setPhotos: (state: IRSSlice, action: TAnyAction<IFilestore[]>) => {
            state.itemPhotos = action.payload;
        },
        setErrors: (state: IRSSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IRSSlice, action: TAnyAction<IRSSearch>) => {
            state.search = action.payload;
        },
        setNavLink: (state: IRSSlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
        setLoading: (state: IRSSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
        setCurrenIndex: (state: IRSSlice, action: TAnyAction<number>) => {
            state.currentIndex = action.payload;
        },
    },
});

export const {
    set,
    setAll,
    setSearch,
    setErrors,
    setPhotos,
    setNavLink,
    setMapItems,
    setChosenMapItems,
    setCurrenIndex,
    setAllOut,
} = RSSlice.actions;

export const goTo =
    (params: Record<string, any>) =>
        (dispatch: TAppDispatch, getState: () => TState): void => {
            if (params.reset === true) {
                params = initialState.search;
            } else params = { ...getState().realtySecondary.search, ...params };

            dispatch(
                redirect(
                    `/realty-secondary?${queryString.stringify(params, { skipNull: true })}`,
                ),
            );
        };

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        dispatch(setAppLoading(true));
        const items = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setNavLink(`/realty-secondary?${queryParams.toString()}`));
        dispatch(setAll(items));
        dispatch(setAppLoading(false));

        return items;
    };
};

export const getAllMap = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<IRSMapModel[]> => {
        queryParams = queryParams || new URLSearchParams();

        queryParams.set('map', '1');

        const items = await api.all(queryParams);

        dispatch(setMapItems(items));

        return items;
    };
};

export const create = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.create(data, files);
        dispatch(setAppLoading(false));
        if (item) {
            dispatch(redirect(getState().realtySecondary.navLink));
            dispatch(redirect(`/realty-secondary/edit/${item.id}`));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.read(id);
        dispatch(set(item));
        dispatch(setAppLoading(false));
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.update(id, data, files);
        if (response.success) {
            dispatch(set(response.data));
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const deleteFile = (id: number, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.deleteFile(id, name);
        if (response) {
            dispatch(
                setPhotos([
                    ...getState()
                        .realtySecondary.itemPhotos.filter(
                            (photo: IFilestore) => photo.name !== name,
                        )
                        .sort(
                            (a: IFilestore, b: IFilestore) => a.queue - b.queue,
                        ),
                ]),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const uploadPhoto = ({ id, files, onUploadProgress }: UploadProps) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.uploadPhotos(id, files, {
            onUploadProgress: onUploadProgress,
        });

        console.log('upload response');
        console.dir(response);

        if (response && Array.isArray(response)) {
            if (getState().realtySecondary.item.id === id) {
                dispatch(
                    setPhotos([
                        ...getState().realtySecondary.itemPhotos,
                        ...response,
                    ]),
                );
            }

            successNotify({
                message: `Загружено ${response.length} фото`,
                title: 'Успешно!',
            });
        }
    };
};

export const setSold = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.update(id, { sold: 1 });
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySecondary.items,
                    items: getState().realtySecondary.items.items.filter(
                        (item: IRSModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const setSoldOff = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.soldOff(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySecondary.items,
                    items: getState().realtySecondary.items.items.filter(
                        (item: IRSModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const favorite = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.favorite(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySecondary.items,
                    items: getState().realtySecondary.items?.items?.map(
                        (item: IRSModel) => {
                            if (item.id === id) {
                                return { ...item, favorite: !item.favorite };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().realtySecondary.item) {
                dispatch(
                    set({
                        ...getState().realtySecondary.item,
                        favorite: !getState().realtySecondary.item.favorite,
                    }),
                );
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const removeRealtyMegasaleInfo = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(
            setAll({
                ...getState().realtySecondary.items,
                items: getState().realtySecondary.items?.items?.map(
                    (item: IRSModel) => {
                        if (item.id === id) {
                            return { ...item, megasale: null };
                        }
                        return item;
                    },
                ),
            }),
        );
        if (getState().realtySecondary.item) {
            dispatch(
                set({
                    ...getState().realtySecondary.item,
                    megasale: null,
                }),
            );
        }
    };
};

export const addRealtyMegasaleInfo = (
    id: number | string,
    megasale: IMegasaleInfoMegasale,
) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(
            setAll({
                ...getState().realtySecondary.items,
                items: getState().realtySecondary.items?.items?.map(
                    (item: IRSModel) => {
                        if (item.id === id) {
                            return { ...item, megasale: megasale };
                        }
                        return item;
                    },
                ),
            }),
        );
        if (getState().realtySecondary.item) {
            dispatch(
                set({
                    ...getState().realtySecondary.item,
                    megasale: megasale,
                }),
            );
        }
    };
};

export const fresh = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.fresh(id);
    };
};

export const xmlfeedaction = (id: number, data: Record<string, boolean>) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.xmlfeedaction(id, data);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySecondary.items,
                    items: getState().realtySecondary.items.items.map(
                        (item: IRSModel) => {
                            if (item.id === id) {
                                return {
                                    ...item,
                                    xmlfeedunload: response.data,
                                };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().realtySecondary.item?.id == id) {
                dispatch(
                    set({
                        ...getState().realtySecondary.item,
                        xmlfeedunload: response.data,
                    }),
                );
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const transfer = (id: number, uid: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.transfer(id, uid);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySecondary.items,
                    items: getState().realtySecondary.items.items.map(
                        (item: IRSModel) => {
                            if (item.id === id) {
                                return { ...response.data };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().realtySecondary.item?.id == id) {
                dispatch(set(response.data));
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.remove(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySecondary.items,
                    items: getState().realtySecondary.items.items.filter(
                        (item: IRSModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const outDateSoon = (queryParams) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.all(queryParams);
        if (response) {
            dispatch(setAllOut(response?.count));
        }
    };
};

export const selectRS = (state: TState): IRSSlice => state.realtySecondary;

export default RSSlice.reducer;

export type IRSSearch = {
    id?: number;
    map?: number;

    limit?: number;
    page?: number;
    sort?: string;

    my?: boolean;
    sold?: boolean;
    withPhoto?: boolean;

    sellerPhone?: string;

    own?: string;
    realtyType?: string;
    realtySubtype?: string | string[];

    agentUid?: string;
    agencyGroupId?: number;

    houseMaterial?: string;

    priceMin?: number;
    priceMax?: number;

    roomsMin?: number;
    roomsMax?: number;
    rooms?: any[] | any;

    storeyMin?: number;
    storeyMax?: number;

    ceilingMin?: number;
    ceilingMax?: number;

    houseStoreyMin?: number;
    houseStoreyMax?: number;

    spaceTotalMin?: number;
    spaceTotalMax?: number;

    housingEstateId?: any;
    livingArea?: any;

    spaceKitchenMin?: number;
    spaceKitchenMax?: number;

    spaceLivingMin?: number;
    spaceLivingMax?: number;

    houseBuiltMin?: number;
    houseBuiltMax?: number;

    parking?: any;
    highflat?: any;
    multilevel?: any;

    isAreaCctv?: any;

    stainedGlass?: any;

    isClosedArea?: any;
    isProtectedArea?: any;
    isAreaSportGround?: any;
    hasBesideWater?: any;
    hasBesideForest?: any;
    hasBesidePark?: any;
    hasBesideMall?: any;
    underfloorHeatingSystem?: any;
    lowRiseEstate?: any;
    masterBedroom?: any;
    smartHouseSystem?: any;
    viewApartment?: any;

    isDiscount?: any;
    isExclusive?: any;
    houseBuiltQuarterMin?: any;
    houseBuiltQuarterMax?: any;

    isSchoolBeside?: any;
    isPreschoolBeside?: any;
    kitchenLiving?: any;
    withoutSellerMinors?: any;
    withSellerMinors?: any;
    withBankEncumbrance?: any;
    withoutBankEncumbrance?: any;

    terrace?: any;

    storeyNotLast?: any;
    storeyNotFirst?: any;

    address?: string;
    addressPlaceDetailId?: number;
    polygon?: string;

    groundParking?: any;
    undergroundParking?: any;
    withoutUnderstatement?: any;

    verified?: any;
    isFullfied?: any;

    onMegasale?: any;

    xmlFeedId?: number;
    roomType?: 'isolated' | 'adjacent';
    renovation?: 'standard' | 'design' | 'no_decoration' | 'euro';
};

export interface IRSMapModel {
    id?: number;
    title?: string;
    price?: number;
    latitude?: number;
    longitude?: number;
}

export interface IRSModel {
    id?: number;
    agent?: IUser;
    agency?: IAgency;
    agentUid?: string;
    addressPlaceDetailId?: number;
    dealType?: string;
    realtyType?: string;
    realtySubtype?: string;
    spaceTotal?: number | string;
    spaceLiving?: number | string;
    spaceKitchen?: number | string;
    ceiling?: number | string;
    price?: string;
    priceRise?: boolean;
    priceChangedAgo?: TPriceChangedAgo;
    rooms?: number;
    videoUrl?: string;
    roomType?: string;
    description?: string;
    privateNote?: string;
    storey?: number | string;
    houseStoreys?: number | string;
    flatNumber?: string;
    balcony?: number;
    loggia?: number;
    terrace?: number;
    bathroomSeparated?: number | string;
    bathroomCombined?: string | number;
    passengerElevator?: number;
    serviceElevator?: number;
    bankEncumbrance?: string | number;
    bankEncumbranceTitle?: string;
    furnitureRemains?: boolean;
    houseMaterial?: string;
    sellerMinors?: number;
    parking?: any;
    highflat?: any;
    multilevel?: any;
    houseBuilt?: any;
    stainedGlass?: boolean;
    kitchenLiving?: boolean;
    isAreaPlayGround?: boolean;
    isAreaSportGround?: boolean;
    canDeveloperRepaire?: boolean;
    isAreaCctv?: boolean;
    isRepaired?: boolean;
    isUndergroundParking?: boolean;
    isGroundParking?: boolean;
    isClosedArea?: boolean;

    housingEstate?: any;

    isProtectedArea?: boolean;
    hasBesideWater?: boolean;
    hasBesideForest?: boolean;
    hasBesidePark?: boolean;
    hasBesideMall?: boolean;
    underfloorHeatingSystem?: boolean;
    lowRiseEstate?: boolean;
    masterBedroom?: boolean;
    smartHouseSystem?: boolean;
    viewApartment?: boolean;
    soundproofing?: boolean;
    isExclusive?: boolean;
    isDiscount?: boolean;

    houseBuiltQuarter?: number;

    isSchoolBeside?: boolean;
    isPreschoolBeside?: boolean;
    isUnderstatementNeed?: boolean;
    understatementSum?: string;
    verified?: boolean;
    verifiedAt?: Date | null;
    verifiedBy?: any;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
    freshAt?: Date;
    freshBy?: string;
    deletedAt?: Date | null;
    deletedBy?: string | null;
    addressDetail?: IAddressDetail;
    photos?: IFilestore[] | [];
    photoCount?: number;
    isMy?: boolean;
    sellerName?: string;
    sellerPhone?: number | string;
    sold?: boolean;
    freshAgo?: number;
    freshAgoH?: number;
    favorite?: boolean;
    isFullfied?: boolean;
    isFullfiedButPhoto?: boolean;
    fullfiedStatus?: { key: string; value: boolean }[];
    fullfiedAt?: Date | null;
    fullfiedFirstAt?: Date | null;
    xmlfeedunload?: IXmlFeedRealty[];
    priceHistory?: IRealtySecondaryPrice[];
    renovation?: 'standard' | 'design' | 'no_decoration' | 'euro';
    isEspecial?: boolean;
    same?: { count: number; rows: number[] };

    megasale?: IMegaSaleRealty;
}

export interface IRealtySecondaryPrice {
    id: number;
    realtySecondaryId: number;
    oldPrice: number;
    newPrice: number;
    rise: boolean;
    createdAt: Date;
    createdBy?: string;
    userCreatedBy?: IUser;
}

export interface IAgent {
    email?: string;
    firstname?: string | null;
    lastname?: string | null;
    middlename?: string | null;
    fullname?: string;
    phone?: number | string;
    uid?: string;
}

export interface IAddressDetail {
    id: number;
    placeId: string;
    shortAddress: string;
    latitude: string | number;
    longitude: string | number;
    formattedAddress: string;
    livingArea?: string;
}

export interface IRSCreation {
    id?: number;
    dealType?: string;
    rooms?: number;
    realtyType?: string;
    realtySubtype?: string;
    price?: string;
    addressPlaceDetailId?: number;
    addressDetail?: Record<string, any>;
    spaceTotal?: string | number;
    spaceLiving?: string | number;
    spaceKitchen?: number | string;
    storey?: number;
    houseStoreys?: number;
    houseBuilt?: number;
    houseMaterial?: string;
    flatNumber?: string;
    bathroomSeparated?: number | string;
    bathroomCombined?: string | number;
    passengerElevator?: string | number;
    serviceElevator?: string | number;
    balcony?: string | number;
    loggia?: string | number;
    description?: string;
    notePrivate?: string;
    photosUpload?: any[];
}

export interface IRSUpdate {
    id: string | number;
    dealType?: string;
    realtyType?: string;
    realtySubtype?: string;
    price?: string;
    addressPlaceDetailId?: number;
    spaceTotal?: string | number;
    spaceKitchen?: string | number;
    storey?: number;
    houseStoreys?: number;
    houseBuilt?: number;
    houseMaterial?: string;
    flatNumber?: string;
    rooms?: number;
    bathroomSeparated?: number;
    bathroomCombined?: number;
    passengerElevator?: number;
    serviceElevator?: number;
    balcony?: number;
    loggia?: number;
    description?: string;
    notePrivate?: string;
    sellerName?: string;
    sellerPhone?: number | string;
}

export type TPriceChangedAgo = {
    status: 'fine' | 'outdated';
    days?: number;
};
