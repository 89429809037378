import { createSlice, Slice } from '@reduxjs/toolkit';
import { TAnyAction, TAppDispatch, TState } from '.';
import { IResponseListData } from '../interfaces/common';
import { IReviews, IReward, IUsersPA } from '../service/user';
import { redirect, setLoading as setAppLoading } from './appSlice';
import * as api from '~/src/service/user';
import { successNotify } from '../helpers/createNotify';
import { getListSocial, ISocial, IUnloadSelectSocial, IUnloadSocial, unload as unloadSocial } from '../service/social';


export const sliceName = 'usersPA';

interface IUsersPASlice {
    item: IUsersPA,
    rewards: IResponseListData<IReward>,
    reviews: IResponseListData<IReviews>,
    social: IResponseListData<ISocial>
    socialUnload: IUnloadSelectSocial[],
}

const initialState = {
    item: {},
    rewards: {},
    reviews: {},
    social: {},
    socialUnload: [],
};

const usersPASlice: Slice<IUsersPASlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        set: (state: IUsersPASlice, action: TAnyAction<IUsersPA>) => {
            state.item = action.payload;
        },
        setAllReward: (state: IUsersPASlice, action: TAnyAction<IResponseListData<IReward>>) => {
            state.rewards = action.payload;
        },
        setAllReviews: (state: IUsersPASlice, action: TAnyAction<IResponseListData<IReviews>>) => {
            state.reviews = action.payload;
        },
        setAllSocial: (state: IUsersPASlice, action: TAnyAction<IResponseListData<ISocial>>) => {
            state.social = action.payload;
        },
        setSocialUnload: (state: IUsersPASlice, action: TAnyAction<IUnloadSelectSocial[]>) => {
            state.socialUnload = action.payload;
        },
    },
});

const {
    set,
    setAllReward,
    setAllReviews,
    setAllSocial,
    setSocialUnload,
} = usersPASlice.actions;


// функции

export const getUser = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const user = await api.getUserPA();
            dispatch(set(user));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const updateUser = (id: number, data: IUsersPA, files?: Record<string, File[]>) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.updateUser(id, data, files);
            if (response.success) {
                dispatch(set(response.data));
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getRewards = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const rewards = await api.getRewards();
        dispatch(setAllReward(rewards));
        dispatch(setAppLoading(false));
    };
};

export const createReward = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.createRewards(data, files);
        if (item) {
            dispatch(
                setAllReward({
                    ...getState().usersPA.rewards,
                    items: [
                        item,
                        ...getState().usersPA.rewards.items,
                    ]
                }),
            );
        }
        successNotify({
            message: 'Добавлено',
            title: 'Успешно!',
        });
        dispatch(setAppLoading(false));
    };
};

export const updateReward = (id, data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.updateRewards(id, data, files);
        if (item) {
            const currentRewards = getState().usersPA.rewards;
            const updatedItems = currentRewards.items?.map(itemCurrent => itemCurrent.id === item.id ? item : itemCurrent);
            dispatch(
                setAllReward({
                    ...currentRewards,
                    items: updatedItems
                })
            );
            successNotify({
                message: 'Запись изменена',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};

export const deleteReward = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.deleteReward(id);
        if (item) {
            const currentRewards = getState().usersPA.rewards;
            const updatedItems = currentRewards.items?.filter(item => item.id !== id);
            dispatch(
                setAllReward({
                    ...currentRewards,
                    items: updatedItems,
                })
            );
            successNotify({
                message: 'Достижение удалено',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};

export const deleteRewardFile = (id: number, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.deleteFileReward(id, name);
        if (item) {
            const currentRewards = getState().usersPA.rewards;
            const updatedItems = currentRewards.items?.map(item => {
                if (item.id === id) {
                    const updatedPhotos = item.photos.filter(photo => photo.name !== name);
                    return { ...item, photos: updatedPhotos };
                }
                return item;
            });
            dispatch(
                setAllReward({
                    ...currentRewards,
                    items: updatedItems,
                })
            );
            successNotify({
                message: 'Фотография удалена',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};

export const getReviews = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const reviews = await api.getReviews();
        dispatch(setAllReviews(reviews));
        dispatch(setAppLoading(false));
    };
};

export const createReviews = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.createReviews(data, files);
        if (item) {
            dispatch(
                setAllReviews({
                    ...getState().usersPA.reviews,
                    items: [
                        item,
                        ...getState().usersPA.reviews.items,
                    ]
                }),
            );
        }
        successNotify({
            message: 'Отзыв добавлен',
            title: 'Успешно!',
        });
        dispatch(setAppLoading(false));
    };
};

export const updateReviews = (id, data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.updateReviews(id, data, files);
        if (item) {
            const currentRewards = getState().usersPA.reviews;
            const updatedItems = currentRewards.items?.map(itemCurrent => itemCurrent.id === item.id
                ? {
                    ...item,
                    files: itemCurrent.files
                }
                : itemCurrent);
            dispatch(
                setAllReviews({
                    ...currentRewards,
                    items: updatedItems
                })
            );
            successNotify({
                message: 'Запись изменена',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};

export const deleteReview = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.deleteReview(id);
        if (item) {
            const currentReviews = getState().usersPA.reviews;
            const updatedItems = currentReviews.items?.filter(item => item.id !== id);
            dispatch(
                setAllReviews({
                    ...currentReviews,
                    items: updatedItems,
                })
            );
            successNotify({
                message: 'Отзыв удален',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};

export const deleteReviewsFile = (id: number, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.deleteFileReviews(id, name);
        if (item) {
            const currentReviews = getState().usersPA.reviews;
            const updatedItems = currentReviews.items?.map(item => {
                if (item.id === id) {
                    const updatedPhotos = item.files.filter(file => file.name !== name);
                    return { ...item, files: updatedPhotos };
                }
                return item;
            });
            dispatch(
                setAllReviews({
                    ...currentReviews,
                    items: updatedItems,
                })
            );
            successNotify({
                message: 'Фотография удалена',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};


export const createSocial = (socialId: number, data: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.connectSocial(socialId, data);
        if (item) {
            dispatch(
                set({
                    ...getState().usersPA.item,
                    socials: [
                        ...getState().usersPA.item.socials,
                        {
                            id: item?.id,
                            data: item?.data,
                            social: item?.social
                        },
                    ]
                }),
            );
        }
        successNotify({
            message: 'Добавлено',
            title: 'Успешно!',
        });
        dispatch(setAppLoading(false));
    };
};

export const deleteSocial = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.deleteConnectSocial(id);
        if (item) {
            dispatch(
                set({
                    ...getState().usersPA.item,
                    socials: getState().usersPA.item.socials.filter(social => social.id !== id)
                }),
            );
        }
        successNotify({
            message: 'Удалено',
            title: 'Успешно!',
        });
        dispatch(setAppLoading(false));
    };
};

export const updateSocial = (id, data: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.updateConnectSocial(id, data);
        if (item) {
            const currentSocial = getState().usersPA.item;
            const updatedItems = currentSocial.socials?.map(itemCurrent => itemCurrent.id === id ? {
                id: item.id,
                data: item.data,
                social: item.social,
            } : itemCurrent);
            dispatch(
                set({
                    ...getState().usersPA.item,
                    socials: updatedItems,
                }),
            );
            successNotify({
                message: 'Запись изменена',
                title: 'Успешно!',
            });
        }
        dispatch(setAppLoading(false));
    };
};

export const unloadedSocial = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const unload = await unloadSocial();
        const social = unload.map(item => {
            return {
                value: item.id,
                label: item.title,
            };
        });
        dispatch(setSocialUnload(social));
        dispatch(setAppLoading(false));
    };
};

export const selectUsersPA = (state: TState): IUsersPASlice => state.usersPA;

export default usersPASlice.reducer;
