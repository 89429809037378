import Form from 'react-bootstrap/Form';
import ru from 'date-fns/locale/ru';
import { Field } from 'react-final-form';
import DatePicker from 'react-datepicker';
import { format, isValid } from 'date-fns';
import { InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './datePickerField.css';

interface IProps {
    name: string;
    label: string;
    validators?: any;
    [key: string]: any;
}

export default function DatePickerField(props: IProps): JSX.Element {
    return (
        <Field {...props} validate={props.validators}>
            {({ input, meta }) => (
                <Form.Group className='mb-2'>
                    <Form.Label>{props.label}</Form.Label>
                    <InputGroup className='flex-nowrap'>
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
                        </InputGroup.Text>
                        <DatePicker
                            {...input}
                            {...props}
                            timeCaption={'Время'}
                            className={classNames([
                                'form-control',
                                {
                                    'is-invalid':
                                        meta.error || meta.submitError,
                                },
                            ])}
                            locale={ru}
                            dateFormat='yyyy-MM-dd HH:mm:00'
                            selected={
                                input.value ? new Date(input.value) : null
                            }
                            openToDate={
                                input.value ? new Date(input.value) : new Date()
                            }
                            showYearDropdown
                            isClearable
                            dropdownMode='select'
                            autoComplete='off'
                            onCalendarClose={() => {
                                if (isValid(input.value)) {
                                    input.onChange(
                                        format(
                                            new Date(input.value),
                                            props.showTimeSelect ? 'yyyy-MM-dd HH:mm:00' : 'yyyy-MM-dd',
                                        ),
                                    );
                                }
                            }}
                        />
                    </InputGroup>
                    <Form.Control.Feedback type='invalid'>
                        {meta.error || meta.submitError}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </Field>
    );
}
