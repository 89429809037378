import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FormApi } from 'final-form';
import { useForm } from 'react-final-form';

import {
    composeValidators,
    mustBeNumber,
    required,
    minEqValue,
    price,
    priceSixDigit,
} from '~/src/helpers/validators';
import InputField from '../../Common/Form/InputField';
import SimpleSelectV2Field from '../../Common/Form/SimpleSelectV2';
import TextareaField from '../../Common/Form/TextareaField';
import { selectRS } from '~/src/store/realtySecondary/realtySecondarySlice';
import { useSelector } from 'react-redux';
import InputFieldError from '../../Common/Form/InputFieldError';
import { selectMegaSale } from '~/src/store/megasaleSlice';

export default function UpdateFlatParamsView(): JSX.Element {
    const form: FormApi = useForm();
    const { item } = useSelector(selectRS);
    const { megasaleActive } = useSelector(selectMegaSale);

    return (
        <React.Fragment>
            <Row>
                {(item?.megasale && megasaleActive?.isStarted) ? (
                    <InputFieldError
                        label='Цена'
                        name='price'
                        type='text'
                        errorMessage={
                            'Объект на распродаже, менять цену запрещено'
                        }
                        onChange={(e) =>
                            form.mutators.setPriceValue('price', e.target.value)
                        }
                        validators={composeValidators(required, price)}
                    />
                ) : (
                    <InputField
                        label='Цена'
                        name='price'
                        type='text'
                        onChange={(e) =>
                            form.mutators.setPriceValue('price', e.target.value)
                        }
                        validators={composeValidators(required, priceSixDigit)}
                    />
                )}
            </Row>
            <Row>
                <Col>
                    <InputField
                        label='Общая площадь'
                        name='spaceTotal'
                        type='number'
                        validators={composeValidators(required)}
                    />
                </Col>
                <Col>
                    <InputField
                        label='Площадь кухни'
                        name='spaceKitchen'
                        type='number'
                        validators={composeValidators(required)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputField
                        type='number'
                        label='Жилая площадь'
                        name='spaceLiving'
                        validators={required}
                    />
                </Col>
                <Col>
                    <InputField
                        label='Высота потолков'
                        name='ceiling'
                        validators={composeValidators(mustBeNumber, required)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputField
                        label='Этаж'
                        name='storey'
                        validators={composeValidators(mustBeNumber, required)}
                    />
                </Col>
                <Col>
                    <InputField
                        label='Этажность'
                        name='houseStoreys'
                        validators={composeValidators(
                            mustBeNumber,
                            required,
                            minEqValue(1),
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SimpleSelectV2Field
                        label='Раздельных санузлов'
                        name='bathroomSeparated'
                    >
                        {Array(6)
                            .fill(1)
                            .map((_, i) => (
                                <option
                                    key={`bathroomSeparated-${i}`}
                                    value={i}
                                >
                                    {i == 0 ? 'Нет' : i}
                                </option>
                            ))}
                    </SimpleSelectV2Field>
                </Col>
                <Col>
                    <SimpleSelectV2Field
                        label='Совмещенных санузлов'
                        name='bathroomCombined'
                    >
                        {Array(6)
                            .fill(1)
                            .map((_, i) => (
                                <option key={`bathroomCombined-${i}`} value={i}>
                                    {i == 0 ? 'Нет' : i}
                                </option>
                            ))}
                    </SimpleSelectV2Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SimpleSelectV2Field
                        label='Пассажирских лифтов'
                        name='passengerElevator'
                    >
                        {Array(6)
                            .fill(1)
                            .map((_, i) => (
                                <option
                                    key={`passengerElevator-${i}`}
                                    value={i}
                                >
                                    {i == 0 ? 'Нет' : i}
                                </option>
                            ))}
                    </SimpleSelectV2Field>
                </Col>
                <Col>
                    <SimpleSelectV2Field
                        label='Грузовых лифтов'
                        name='serviceElevator'
                    >
                        {Array(6)
                            .fill(1)
                            .map((_, i) => (
                                <option key={`serviceElevator-${i}`} value={i}>
                                    {i == 0 ? 'Нет' : i}
                                </option>
                            ))}
                    </SimpleSelectV2Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    {form.getState().values.realtyType === 'secondary' ? (
                        <InputField
                            name='houseBuilt'
                            label='Год постройки'
                            validators={composeValidators(
                                required,
                                mustBeNumber,
                                minEqValue(1900),
                            )}
                        />
                    ) : (
                        <Row>
                            <Col sm={9}>
                                <InputField
                                    name='houseBuilt'
                                    label='Срок ввода дома в эксплуатацию'
                                    validators={composeValidators(
                                        required,
                                        mustBeNumber,
                                    )}
                                />
                            </Col>
                            <Col sm={3}>
                                <SimpleSelectV2Field
                                    label='Квартал'
                                    name='houseBuiltQuarter'
                                    onChange={(e) =>
                                        form.mutators.setHouseBuiltQuarter(
                                            'houseBuiltQuarter',
                                            e.target.value,
                                        )
                                    }
                                    validators={required}
                                >
                                    {Array(5)
                                        .fill(1)
                                        .map((index, i) =>
                                            i == 0 ? (
                                                <option
                                                    key={`houseBuiltQuarter-${i}`}
                                                    value={null}
                                                >
                                                    нет
                                                </option>
                                            ) : (
                                                <option
                                                    key={`houseBuiltQuarter-${i}`}
                                                    value={i}
                                                >
                                                    {i}
                                                </option>
                                            ),)}
                                </SimpleSelectV2Field>
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col>
                    <SimpleSelectV2Field
                        label='Материал дома'
                        name='houseMaterial'
                        validators={composeValidators(required)}
                    >
                        <option>Укажите материал</option>
                        <option value='brick'>Кирпич</option>
                        <option value='panel'>Панель</option>
                        <option value='monolith'>Монолит</option>
                        <option value='block'>Блочный</option>
                        <option value='wood'>Деревянный</option>
                    </SimpleSelectV2Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SimpleSelectV2Field
                        label='Количество балконов'
                        name='balcony'
                    >
                        {Array(6)
                            .fill(1)
                            .map((_, i) => (
                                <option key={`balcony-${i}`} value={i}>
                                    {i == 0 ? 'Нет' : i}
                                </option>
                            ))}
                    </SimpleSelectV2Field>
                </Col>
                <Col>
                    <SimpleSelectV2Field
                        label='Количество лоджий'
                        name='loggia'
                    >
                        {Array(6)
                            .fill(1)
                            .map((_, i) => (
                                <option key={`loggia-${i}`} value={i}>
                                    {i == 0 ? 'Нет' : i}
                                </option>
                            ))}
                    </SimpleSelectV2Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SimpleSelectV2Field
                        label='Ремонт'
                        name='renovation'
                        validators={required}
                    >
                        {form.getState().values.realtyType == 'secondary' ? (
                            <>
                                <option></option>
                                <option value='standard'>Косметический</option>
                                <option value='design'>Дизайнерский</option>
                                <option value='no_decoration'>Требуется</option>
                                <option value='euro'>Евро</option>
                            </>
                        ) : (
                            <>
                                <option></option>
                                <option value='withoutFinishing'>
                                    Без отделки
                                </option>
                                <option value='whiteBox'>Предчистовая</option>
                                <option value='fineFinishing'>Чистовая</option>
                            </>
                        )}
                    </SimpleSelectV2Field>
                </Col>
                <Col>
                    <SimpleSelectV2Field
                        label='Тип комнат'
                        name='roomType'
                        validators={required}
                    >
                        <option></option>
                        <option value='isolated'>Изолированные</option>
                        <option value='adjacent'>Смежные</option>
                    </SimpleSelectV2Field>
                </Col>
            </Row>
            <InputField name='videoUrl' label='Ссылка на видео' />
            <TextareaField
                label='Описание'
                name='description'
                validators={required}
            />
            <TextareaField label='Заметка для себя' name='privateNote' />
        </React.Fragment>
    );
}
