import { Link } from 'react-router-dom';
import RSListPhotos from '../../Realty/Secondary/RSListPhotos';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import classNames from 'classnames';
import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';

import {
    IMegaSaleRealtySecondary,
    removeRealtyMegasale,
    verifyRealtyMegasale,
} from '~/src/store/megasaleSlice';
import { useDispatch } from 'react-redux';
import IListPriceRise from './IListPriceRise';

const ListSecondaryRealty: FC<TListProps> = ({ item }) => {
    const dispatch = useDispatch();
    const price = new Intl.NumberFormat('ru-RU').format(+item.realty?.price);
    const ppriceMegaSale = new Intl.NumberFormat('ru-RU').format(+item?.price);
    const discount = new Intl.NumberFormat('ru-RU').format(
        +item.realty?.price - +item?.price,
    );

    const handleVerifyMegasaleRealty = () => {
        dispatch(verifyRealtyMegasale(item.id));
    };
    const handleRejectVerifyMegasaleRealty = () => {
        dispatch(removeRealtyMegasale(item.id));
    };

    const handleRemoveRealtyMegasale = () => {
        dispatch(removeRealtyMegasale(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={'/sale'}>{item.realty?.id}</Link>
                    {item.realty?.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {!item.realty?.isFullfied && (
                        <React.Fragment>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                title={item.realty?.fullfiedStatus
                                    ?.map((field) => {
                                        return `${field.key}: ${field.value === true ? 'Да' : 'Нет'}`;
                                    })
                                    .join('\n')}
                            />
                        </React.Fragment>
                    )}
                    {item.realty?.xmlfeedunload &&
                        item.realty?.xmlfeedunload.length > 0 && (
                            <React.Fragment>
                                <br />
                                {item.realty?.xmlfeedunload.map((feed) => {
                                    if (feed.enabled) {
                                        switch (feed.feed?.type) {
                                            case 'avito':
                                                return (
                                                    <p>
                                                        <img
                                                            src={avitoLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'cian':
                                                return (
                                                    <p>
                                                        <img
                                                            src={cianLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'domclick':
                                                return (
                                                    <p>
                                                        <img
                                                            src={domclickLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'yandex':
                                                return (
                                                    <p>
                                                        <img
                                                            src={yandexLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                        }
                                    }
                                })}
                            </React.Fragment>
                        )}
                    {item.realty?.isExclusive && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Эксклюзивный</Badge>
                        </React.Fragment>
                    )}
                    {item.realty?.isDiscount && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Промо</Badge>
                        </React.Fragment>
                    )}
                    {item.isLiquid && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Ликвид</Badge>
                        </React.Fragment>
                    )}
                </td>
                <td>
                    <RSListPhotos photos={item.realty?.photos} />
                    {item.realty?.sold && <Badge bg='danger'>В архиве</Badge>}
                </td>
                <td>
                    <p>
                        {
                            item.realty?.realtyType === 'secondary'
                            ? <strong>Вторичка</strong>
                            : <strong>Новостройка от дольщика</strong>
                        }
                    </p>
                    <p>
                        <strong>Тип</strong>
                        <br />
                        <FormattedMessage
                            id={`realty_secondary_subtype_${item.realty?.realtySubtype}`}
                        />
                    </p>
                    {item.realty?.realtyType === 'primary' &&
                        item.realty?.housingEstate && (
                            <p>{item.realty?.housingEstate?.title}</p>
                        )}
                    {item.realty?.addressDetail?.livingArea && (
                        <p>
                            <strong>Район</strong> <br />
                            {item.realty?.addressDetail?.livingArea}
                        </p>
                    )}
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                        // onClick={handleClickAddress}
                        >
                            {item.realty?.addressDetail?.shortAddress}
                        </Button>
                    </p>
                    {item.realty?.storey && item.realty?.houseStoreys && (
                        <p>
                            <strong>Этаж</strong>
                            <br />
                            {item.realty?.storey} / {item.realty?.houseStoreys}
                        </p>
                    )}
                    {item.realty?.isMy && (
                        <p>
                            <strong>Квартира</strong>
                            <br />
                            {item.realty?.flatNumber}
                        </p>
                    )}
                </td>
                <td>
                    {item.realty?.rooms && (
                        <p>
                            <strong>Комнат</strong>
                            <br />
                            {item.realty?.rooms}
                        </p>
                    )}
                    <strong>Площадь:</strong>
                    <br />
                    {item.realty?.spaceTotal && (
                        <p>
                            <span>Общая: </span>
                            {item.realty?.spaceTotal}
                        </p>
                    )}
                    {item.realty?.spaceLiving && (
                        <p>
                            <span>Жилая: </span>
                            {item.realty?.spaceLiving}
                        </p>
                    )}
                    {item.realty?.spaceKitchen && (
                        <p>
                            <span>Кухня: </span>
                            {item.realty?.spaceKitchen}
                        </p>
                    )}
                    {(Number(item.realty?.bathroomCombined) > 0 ||
                        Number(item.realty?.bathroomSeparated) > 0) && (
                            <p>
                                <strong>Санузлы</strong>
                                {Number(item.realty?.bathroomCombined) > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Совмещенных:{' '}
                                            {item.realty?.bathroomCombined}
                                        </span>
                                    </React.Fragment>
                                )}
                                {Number(item.realty?.bathroomSeparated) > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Раздельных:{' '}
                                            {item.realty?.bathroomSeparated}
                                        </span>
                                    </React.Fragment>
                                )}
                            </p>
                        )}
                    {item.realty?.balcony > 0 && (
                        <p>
                            <strong>Балкон: </strong> {item.realty?.balcony}
                        </p>
                    )}
                    {item.realty?.loggia > 0 && (
                        <p>
                            <strong>Лоджия: </strong> {item.realty?.loggia}
                        </p>
                    )}
                </td>
                <td>
                    {item.realty?.houseMaterial && (
                        <p>
                            <strong>Материал стен</strong>
                            <br />
                            <FormattedMessage
                                id={`house_material_${item.realty?.houseMaterial}`}
                            />
                        </p>
                    )}

                    {item.realty?.houseBuilt && (
                        <p>
                            {item.realty?.realtyType == 'primary' ? (
                                <>
                                    <strong>Срок сдачи дома</strong>
                                    <br />
                                    {`${item.realty?.houseBuiltQuarter} кв. ${item?.realty?.houseBuilt} г.`}
                                </>
                            ) : (
                                <>
                                    <strong>Год постройки</strong>
                                    <br />
                                    {`${item.realty?.houseBuilt} г.`}
                                </>
                            )}
                            <br />
                        </p>
                    )}

                    {(item.realty?.serviceElevator > 0 ||
                        item.realty?.passengerElevator > 0) && (
                            <p>
                                <strong>Лифты</strong>
                                {item.realty?.passengerElevator > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Пассажирских:{' '}
                                            {item.realty?.passengerElevator}
                                        </span>
                                    </React.Fragment>
                                )}
                                {item.realty?.serviceElevator > 0 && (
                                    <React.Fragment>
                                        <br />
                                        <span>
                                            Грузовых: {item.realty?.serviceElevator}
                                        </span>
                                    </React.Fragment>
                                )}
                            </p>
                        )}
                </td>
                <td
                    style={{
                        minWidth: '200px',
                    }}
                >
                    <p>
                        <strong style={{ color: 'red' }}>Цена</strong>
                        <br />
                        <span style={{ color: 'red' }}>{price}</span>
                        <br></br>
                        <strong style={{ color: 'green' }}>
                            Цена для распродажи
                        </strong>
                        <br />
                        <span style={{ color: 'green' }}>{ppriceMegaSale}</span>
                        <br></br>
                        <strong>Скидка</strong>
                        <br />
                        <span>{discount}</span>
                        <br></br>
                        <strong>
                            Цена за м<sup>2</sup>
                        </strong>
                        <br></br>
                        <span>
                            {new Intl.NumberFormat('ru-RU').format(
                                Math.floor(
                                    +item.realty?.price /
                                    +item.realty?.spaceTotal,
                                ),
                            )}
                        </span>
                        <br></br>
                        <IListPriceRise item={item} agent={item?.realty?.agent} priceHistory={item.realty.priceHistory}/>
                    </p>
                    {item.realty?.isUnderstatementNeed ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Занижение
                            </strong>
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.realty?.understatementSum,
                            )}
                            <br />
                        </p>
                    ) : null}
                    {+item.realty?.bankEncumbrance > 0 ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Обременение
                            </strong>
                            <br />
                            {item.realty?.bankEncumbranceTitle}
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.realty?.bankEncumbrance,
                            )}
                            <br />
                        </p>
                    ) : null}
                </td>
                <td>
                    {item.realty?.isMy ? (
                        <React.Fragment>
                            {item.realty?.sellerName && (
                                <p>
                                    <strong>Продавец</strong>
                                    <br />
                                    {item.realty?.sellerName}
                                </p>
                            )}

                            {item.realty?.sellerPhone && (
                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(
                                        `${item.realty?.sellerPhone}`,
                                    )}
                                </p>
                            )}
                        </React.Fragment>
                    ) : (
                        item.realty?.agent && (
                            <React.Fragment>
                                {item.realty?.agent?.agencyInfo?.agency && (
                                    <p>
                                        <strong>Агентство</strong>
                                        <br></br>
                                        <span>
                                            {
                                                item.realty?.agent?.agencyInfo
                                                    ?.agency.title
                                            }
                                        </span>
                                    </p>
                                )}
                                <p>
                                    <strong>
                                        {item?.realty?.agent?.role != 'agent'
                                            ? 'Собственник'
                                            : 'Агент'}
                                    </strong>
                                    <br />
                                    {item.realty?.agent?.fullname}
                                </p>

                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(
                                        `${item.realty?.agent?.phone}`,
                                    )}
                                </p>
                            </React.Fragment>
                        )
                    )}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Link
                            to={`/realty-secondary/card/${item.realty?.id}`}
                            className='btn btn-sm btn-primary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <ButtonGroup>
                            <Button
                                size='sm'
                                variant={
                                    item.isVerified ? 'success' : 'warning'
                                }
                                title='Верификация'
                                onClick={handleVerifyMegasaleRealty}
                            >
                                {item.isVerified
                                    ? 'Объект прошел модерацию'
                                    : 'Подтвердить объект'}
                            </Button>
                            {!item.isVerified && (
                                <Button
                                    size='sm'
                                    variant='danger'
                                    title='Верификация'
                                    onClick={handleRejectVerifyMegasaleRealty}
                                >
                                    Отклонить
                                </Button>
                            )}
                        </ButtonGroup>
                        <Button
                            size='sm'
                            variant='outline-danger'
                            title='Снять с распродажи'
                            onClick={handleRemoveRealtyMegasale}
                        >
                            Снять с распродажи
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

type TListProps = {
    item: IMegaSaleRealtySecondary;
};

export default ListSecondaryRealty;
