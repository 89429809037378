import { AnyAction, createSlice, Slice, ThunkAction } from '@reduxjs/toolkit';
import type { TState, TAnyAction, TAppDispatch, RootState } from '~/src/store';
import { redirect, setLoading as setAppLoading, setLoading } from './appSlice';
import * as api from '~/src/service/megasale';
import queryString from 'query-string';
import { IResponseListData } from '../interfaces/common';
import { IHousingEstateHousePorchStoreyFlat } from '../service/housing.estate.house.porch.storey.flat';
import { IRSModel } from './realtySecondary/realtySecondarySlice';
import { IHousingEstateHousePorchStorey } from '../service/housing.estate.house.porch.storey';
import { IRSUBModel } from './realtySuburban/realtySuburbanSlice';

export const sliceName = 'megasale';

interface IMegasaleSlice {
    items: IResponseListData<IMegaSaleRealty>;
    item?: IMegaSaleRealty;
    megasaleActive?: any;
    megasaleCreate?: any;
    addMegasaleRealty?: any;
    verifyMegasaleRealty?: any;
    search?: ISearchMegasale;
    navLink?: string;
    baseLink?: string;
    errors?: any;
}

const initialState: IMegasaleSlice = {
    items: {},
    megasaleCreate: {},
    addMegasaleRealty: {},
    search: {
        limit: 25,
        page: 1,
    },
    navLink: '/sale',
    baseLink: '/sale',
    errors: {},
};

const MegaSaleSlice: Slice<any> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAllMegasaleRealty: (
            state: any,
            action: TAnyAction<IResponseListData<IMegaSaleRealty>>,
        ) => {
            state.items = action.payload;
        },
        setMegaSaleActive: (state: any, action: TAnyAction<any>) => {
            state.megasaleActive = action.payload;
        },
        setMegasaleCreate: (state: any, action: TAnyAction<any>) => {
            state.megasaleCreate = action.payload;
        },
        setAddMegasaleRealty: (state: any, action: TAnyAction<any>) => {
            state.addMegasaleRealty = action.payload;
        },
        setVerifyMegasaleRealty: (state: any, action: TAnyAction<any>) => {
            state.verifyMegasaleRealty = action.payload;
        },
        setErrors: (state: any, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: any, action: TAnyAction<ISearchMegasale>) => {
            state.search = action.payload;
        },
        setNavLink: (state: any, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
    },
});

const {
    setMegaSaleActive,
    setMegasaleCreate,
    setAddMegasaleRealty,
    setErrors,
    setAllMegasaleRealty,
    setVerifyMegasaleRealty,
    setSearch,
    setNavLink,
} = MegaSaleSlice.actions;

export const allMegasaleRealty = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        dispatch(setAppLoading(true));
        const response = await api.allMegasaleRealty(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setNavLink(`/sale?${queryParams.toString()}`));
        dispatch(setAllMegasaleRealty(response));
        dispatch(setAppLoading(false));

        return response;
    };
};

export const goTo =
    (params: Record<string, any>) =>
        (dispatch: TAppDispatch, getState: () => TState): void => {
            if (params.reset === true) {
                params = initialState.search;
            } else params = { ...getState().megasale.search, ...params };

            dispatch(
                redirect(
                    `/sale?${queryString.stringify(params, { skipNull: true })}`,
                ),
            );
        };

export const checkMegasale = () => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const megasale = await api.checkMegasale();
            if (megasale) {
                dispatch(setMegaSaleActive(megasale));
                return megasale;
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};
export const createMegasale = (data: api.ICreateMegasale) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.createMegasale(data);
            if (response) {
                dispatch(setMegaSaleActive(response));
                dispatch(setMegasaleCreate(response));
                return response;
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const addRealtyMegasale = (data: api.IAddRealtyMegasale): any => {
    return async (dispatch) => {
        try {
            const response = await api.addRealtyMegasale(data);
            if (response) {
                dispatch(setAddMegasaleRealty(response?.data));
                return response;
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        }
    };
};

export const removeRealtyMegasale = (id: number | string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<any> => {
        try {
            const response = await api.removeRealtyMegasale(id);
            if (response) {
                console.log(response);
                dispatch(
                    setAllMegasaleRealty({
                        ...getState().megasale.items,
                        items: getState().megasale.items.items.filter(
                            (item) => item.id != id,
                        ),
                        count: getState().megasale.items.count - 1,
                    }),
                );
                return response;
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        }
    };
};
export const verifyRealtyMegasale = (id: number | string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<any> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoading(true));
            const response = await api.verifyRealtyMegasale(id);
            if (response) {
                dispatch(
                    setAllMegasaleRealty({
                        ...getState().megasale.items,
                        items: getState().megasale.items?.items?.map((item) => {
                            if (item.id === id) {
                                return {
                                    ...item,
                                    isVerified: !item.isVerified,
                                };
                            }
                            return item;
                        }),
                    }),
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
            dispatch(setLoading(false));
        }
    };
};

export const selectMegaSale = (state: TState): IMegasaleSlice => state.megasale;

export default MegaSaleSlice.reducer;

export interface IMegaSaleRealty {
    id: number;
    megasaleId: number;
    realtyType: string;
    realtyId: number;
    price: number;
    isVerified?: boolean;
    isLiquid?: boolean;
    verifiedAt?: string | null;
    verifiedBy?: string | null;
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
    megasale?: IMegasaleInfoMegasale;
}

export interface IMegaSaleRealtySecondary extends IMegaSaleRealty {
    realty?: IRSModel;
}

export interface IMegaSaleRealtyPrimary extends IMegaSaleRealty {
    realty?: IHousingEstateHousePorchStoreyFlat;
}
export interface IMegaSaleRealtySuburban extends IMegaSaleRealty {
    realty?: IRSUBModel;
}

export interface IMegasaleInfoMegasale {
    id?: number;
    dateStart?: string;
    dateFinish?: string;
    note?: any;
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
}

export interface IMegasaleAddRealty {
    success: boolean;
    data: IMegaSaleRealty;
}

export interface ISearchMegasale {
    limit: number;
    page: number;
    verified?: number;
}
