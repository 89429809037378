import React, {
    SyntheticEvent,
    useState,
    useEffect,
    BaseSyntheticEvent,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import {
    IRSSearch,
    selectRS,
    goTo,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import SearchHiddenForm from './searchHiddenForm';
import {
    ownBtns,
    realtyRoomsBtns,
    realtySubtypesBtns,
} from '../../../../common/data/search.form';
import OwnBtns from '~/src/components/Common/FormFilter/OwnBtn';
import { useQuery } from '~/src/helpers/hooks';
import RealtyType from './RealtyTypeBtns';
import Checkbox from '../../../Common/FormFilter/Checkbox';
import MultiCheckboxValue from '../../../Common/FormFilter/MultiCheckboxValue';
import { selectMegaSale } from '~/src/store/megasaleSlice';

const SearchForm = ({ showForm, toggleShowForm }) => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { search, itemsOut } = useSelector(selectRS);
    const { megasaleActive } = useSelector(selectMegaSale);

    const [formFilter, setFormFilter] = useState<IRSSearch>({
        realtySubtype: [],
    });

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        const filter = {};

        for (const [key, value] of Object.entries(formFilter)) {
            if (key === 'priceMin' || key === 'priceMax') {
                filter[key] = value.toString().replaceAll(/\D/g, '');
            } else if (key === 'id') {
                const idsArray = value
                    .split(',')
                    .map(id => id.trim())
                    .filter(id => id !== '');
                const numericIdsArray = idsArray.map(Number).filter(n => !isNaN(n));
                filter[key] = numericIdsArray;
            } else {
                filter[key] = value;
            }
        }

        filter['page'] = 1;
        showForm && toggleShowForm();
        dispatch(goTo(filter));
    };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        setFormFilter((prevFormFilter) => {
            const updatedFormFilter = {
                ...prevFormFilter,
                [e.target.name]: formFilter[e.target.name] == '1' ? null : '1',
            };
            dispatch(goTo(updatedFormFilter));
            return updatedFormFilter;
        });
    };

    // useEffect(() => {
    //     setFormFilter(search);
    // }, [query.toString(), search]);

    return (
        <>
            <Form className='mb-2 mt-2 bg-light p-3' onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    <Col xs={5}>
                        <OwnBtns
                            formFilter={formFilter}
                            setFormFilter={setFormFilter}
                            itemsOut={itemsOut}
                            goTo={goTo}
                            data={ownBtns}
                        />
                    </Col>
                </Row>
                <Row className='mb-3 d-flex align-items-center'>
                    <Col
                        className='d-flex align-items-center justify-content-between gap-5'
                        xs={4}
                    >
                        <RealtyType
                            formFilter={formFilter}
                            setFormFilter={setFormFilter}
                        />
                        <MultiCheckboxValue
                            formFilter={formFilter}
                            setFormFilter={setFormFilter}
                            data={realtyRoomsBtns}
                            idSelector={'rooms-btns'}
                            nameSelector={'rooms'}
                            goTo={goTo}
                        />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <MultiCheckboxValue
                            formFilter={formFilter}
                            setFormFilter={setFormFilter}
                            data={realtySubtypesBtns}
                            idSelector={'subtype-btns'}
                            nameSelector={'realtySubtype'}
                            goTo={goTo}
                        />
                    </Col>
                </Row>
                <Row className='mb-3 d-flex align-items-center'>
                    {!showForm && (
                        <Col className='p-2' xs={1}>
                            <Button type='submit' variant='primary' size='sm'>
                                Найти
                            </Button>
                        </Col>
                    )}
                    <Col className='d-flex gap-5' xs={1}>
                        <Checkbox
                            name='isExclusive'
                            label='Эксклюзивные'
                            onChange={handleChangeCheckboxValue}
                            checked={formFilter.isExclusive == '1'}
                        />
                        <Checkbox
                            name='isDiscount'
                            label='Промо'
                            onChange={handleChangeCheckboxValue}
                            checked={formFilter.isDiscount == '1'}
                        />
                        {
                            megasaleActive && <Checkbox
                                name='onMegasale'
                                label='Распродажа'
                                onChange={handleChangeCheckboxValue}
                                checked={formFilter.onMegasale == '1'}
                            />
                        }
                    </Col>
                </Row>
                <SearchHiddenForm
                    show={showForm}
                    toggleShowForm={toggleShowForm}
                    formFilter={formFilter}
                    setFormFilter={setFormFilter}
                />
            </Form>
        </>
    );
};

export default SearchForm;
