import { useState } from 'react';
import Error from '../../Common/Form/Error';
import { useNavigate } from 'react-router-dom';
import { geocode } from '~/src/service/address';
import { selectAuth } from '~/src/store/authSlice';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '~/src/store/realtySuburban/realtySuburbanSlice';
import MapUpdateView from '../../HousingEstate/MapUpdateView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleButtonField from '../../Common/Form/ToggleButtonWrapper';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { checkDouble } from '~/src/service/realty.suburban';
import DropDownSearchApi from '~/src/components/Common/DropDownSearchApi';
import {
    Button,
    ButtonGroup,
    Container,
    Form,
    Navbar,
    Row,
    Col,
} from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
    onlyLength,
    priceSixDigit,
    priceFiveDigit,
} from '~/src/helpers/validators';
import CheckboxField from '../../Common/Form/CheckboxField';
import SimpleSelectV2Field from '../../Common/Form/SimpleSelectV2';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import { landAppointmentSelect } from '~/src/common/data/realtySuburban/realty.suburba.landAppointment';
import InputField from '../../Common/Form/InputField';
import { createCn } from '~/src/helpers';
import { CN_REGEX_VALIDATE } from '~/src/constants';
import { selectDirection } from '~/src/store/directionSlice';
import { selectCV } from '~/src/store/cottage.village/cottage.village.store';

const RSUBCreateForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [uploadFiles, setUploadFiles] = useState(null);
    const [holdSubmit, setHoldSubmit] = useState(false);
    const [isDouble, setIsDouble] = useState(false);
    const [doubleId, setDoubleId] = useState(null);

    const { me } = useSelector(selectAuth);
    const { unloadItems } = useSelector(selectDirection);
    const { unloadCVItems } = useSelector(selectCV);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(
            create(
                {
                    ...createInitialFormValues(values),
                    price: values.price.replaceAll(/\D/g, ''),
                },
                { photosUpload: uploadFiles },
            ),
        );
    };

    const debounced = useDebouncedCallback(async (cn, userUid) => {
        setHoldSubmit(true);
        if (cn && userUid) {
            const res = await checkDouble({ cn, userUid });
            if (res) {
                setHoldSubmit(false);
                setIsDouble(res.double);
                setDoubleId(res.id);
            }
        }
    }, 2000);

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={
                {
                    // For tests
                    // 'addressPlaceDetailId': '3697',
                }
            }
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setPriceValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(/\D/g, '');
                    changeValue(state, field, () =>
                        new Intl.NumberFormat('ru-RU').format(value),);
                },
                setCnValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => createCn(value));
                    debounced(state.formState.values['cn'], me.uid);
                },
                setAddressCheckValue: async (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => value);
                    debounced(state.formState.values['cn'], me.uid);
                },
            }}
            validate={(values) => {
                const errors: any = {};
                // if (!values.addressPlaceDetailId) {
                //     errors.addressPlaceDetailId = 'Выберите адрес';
                // }
                if (!values.type) {
                    errors.type = 'Укажите тип недвижимости';
                }
                if (!CN_REGEX_VALIDATE.test(values.cn)) {
                    errors.cn = 'Невалидный кадастровый номер';
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between mb-3'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={
                                    submitting ||
                                    !valid ||
                                    isDouble ||
                                    holdSubmit
                                }
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <Form.Group>
                            {/* <ButtonGroup className='mb-2'>
                                <ToggleButtonField
                                    label='Продажа'
                                    type='radio'
                                    name='dealType'
                                    value='sale'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <ToggleButtonField
                                    label='Аренда'
                                    type='radio'
                                    name='dealType'
                                    value='rent'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                            </ButtonGroup> */}
                        </Form.Group>
                        <Form.Group>
                            <ButtonGroup className='mb-2'>
                                <ToggleButtonField
                                    type='radio'
                                    label='Земельный участок'
                                    name='type'
                                    value='land'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <ToggleButtonField
                                    type='radio'
                                    label='Дом/коттедж'
                                    name='type'
                                    value='house'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <ToggleButtonField
                                    type='radio'
                                    label='Дача'
                                    name='type'
                                    value='chalet'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                            </ButtonGroup>
                            <Error name='type' />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Field
                                    name='landAppointment'
                                    validate={required}
                                >
                                    {({ input, meta }) => (
                                        <SimpleSelectV2Field
                                            label='Назначение земельного участка'
                                            name='landAppointment'
                                            validators={required}
                                        >
                                            <option></option>
                                            {landAppointmentSelect.map((el) => (
                                                <option
                                                    key={el.value}
                                                    value={el.value}
                                                >
                                                    {el.label}
                                                </option>
                                            ))}
                                        </SimpleSelectV2Field>
                                    )}
                                </Field>
                                <DropDownSearchApi
                                    label='Адрес'
                                    name='address'
                                    validator={required}
                                    defaultValue={''}
                                    onSelect={(item) => {
                                        form.mutators.setAddressCheckValue(
                                            'addressPlaceDetailId',
                                            item.placeId,
                                        );
                                        form.mutators.setValue(
                                            'address',
                                            item.address,
                                        );
                                        form.mutators.setValue(
                                            'latitude',
                                            item.latitude,
                                        );
                                        form.mutators.setValue(
                                            'longitude',
                                            item.longitude,
                                        );
                                    }}
                                >
                                    <Field name='addressPlaceDetailId'>
                                        {({ meta }) => (
                                            <Form.Group>
                                                <Form.Control.Feedback
                                                    type='invalid'
                                                    style={{
                                                        display:
                                                            meta.error ||
                                                                meta.submitError ||
                                                                isDouble
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    {meta.error ||
                                                        meta.submitError ||
                                                        (isDouble && (
                                                            <a
                                                                href={`/realty-suburban/card/${doubleId}`}
                                                                target='_blank'
                                                                rel='noreferrer'
                                                            >
                                                                Такой объект уже
                                                                есть в базе{' '}
                                                                {doubleId}
                                                            </a>
                                                        ))}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </DropDownSearchApi>
                                <div className='d-flex gap-3'>
                                    <CheckboxField
                                        name='isExclusive'
                                        label='Эксклюзив'
                                    />
                                    <CheckboxField
                                        name='fromLegalEntity'
                                        label='От юридического лица'
                                    />
                                    <CheckboxField
                                        name='ruralMortgage'
                                        label='Ипотека с гос. поддержкой'
                                    />
                                </div>
                                <Field name='cottageVillageId'>
                                    {({ input, meta }) => (
                                        <SimpleSelectV2Field
                                            label='КП'
                                            name='cottageVillageId'
                                        >
                                            <option></option>
                                            {unloadCVItems.map((el) => (
                                                <option
                                                    key={el.id}
                                                    value={el.id}
                                                >
                                                    {el.title}
                                                </option>
                                            ))}
                                        </SimpleSelectV2Field>
                                    )}
                                </Field>
                                <Field name='directionId' validate={required}>
                                    {({ input, meta }) => (
                                        <SimpleSelectV2Field
                                            label='Тракт'
                                            name='directionId'
                                            validators={required}
                                        >
                                            <option></option>
                                            {unloadItems.map((el) => (
                                                <option
                                                    key={el.id}
                                                    value={el.id}
                                                >
                                                    {el.title}
                                                </option>
                                            ))}
                                        </SimpleSelectV2Field>
                                    )}
                                </Field>
                                <InputField
                                    name='cn'
                                    label='Кадастровый номер земельного участка'
                                    onChange={(e) =>
                                        form.mutators.setCnValue(
                                            'cn',
                                            e.target.value,
                                        )
                                    }
                                    validators={required}
                                />
                                <Field name='price' validate={priceFiveDigit}>
                                    {({ input, meta }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Цена</Form.Label>
                                            <Form.Control
                                                {...input}
                                                type='text'
                                                size='sm'
                                                placeholder='Цена'
                                                isInvalid={
                                                    meta.error ||
                                                    meta.submitError
                                                }
                                                onChange={(e) =>
                                                    form.mutators.setPriceValue(
                                                        'price',
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Col>
                            <Col>
                                <FormSpy subscription={{ values: true }}>
                                    {({ values }) => (
                                        <MapUpdateView
                                            latitude={
                                                values.latitude || undefined
                                            }
                                            longitude={
                                                values.longitude || undefined
                                            }
                                            onClick={async (lat, lng) => {
                                                const res = await geocode(
                                                    lat,
                                                    lng,
                                                );

                                                if (
                                                    Array.isArray(res) &&
                                                    res.length > 0
                                                ) {
                                                    form.mutators.setAddressCheckValue(
                                                        'addressPlaceDetailId',
                                                        res[0].placeId,
                                                    );
                                                    form.mutators.setValue(
                                                        'address',
                                                        res[0].address,
                                                    );
                                                    form.mutators.setValue(
                                                        'latitude',
                                                        res[0].latitude,
                                                    );
                                                    form.mutators.setValue(
                                                        'longitude',
                                                        res[0].longitude,
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </FormSpy>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name='sellerName' validate={required}>
                                    {({ input, meta }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Продавец</Form.Label>
                                            <Form.Control
                                                {...input}
                                                type='text'
                                                size='sm'
                                                isInvalid={
                                                    meta.error ||
                                                    meta.submitError
                                                }
                                                placeholder='Продавец'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Col>
                            <Col>
                                <Field
                                    name='sellerPhone'
                                    validate={composeValidators(
                                        required,
                                        mustBeNumber,
                                        onlyLength(10),
                                    )}
                                >
                                    {({ input, meta }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>
                                                Телефон продавца{' '}
                                                <small className='text-muted'>
                                                    (10 цифр без 8)
                                                </small>
                                            </Form.Label>
                                            <Form.Control
                                                {...input}
                                                type='number'
                                                size='sm'
                                                isInvalid={
                                                    meta.error ||
                                                    meta.submitError
                                                }
                                                placeholder='Телефон продавца'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </Col>
                        </Row>
                        <Field name='privateNote'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Заметка для себя</Form.Label>
                                    <Form.Control
                                        {...input}
                                        as='textarea'
                                        rows={4}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Заметка для себя'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        {/* <Form.Group className='mb-3'>
                            <label className='btn btn-sm btn-primary'>
                                <FileField
                                    name='photosUpload'
                                    onChange={e => changeHandler(e, form)}
                                    multiple
                                /> Загрузить фото
                            </label>
                            {preview && preview.length > 0 && <div>{preview.map((image, index) => {
                                return <Image key={`imagePreview${index}`} src={image} width={160} height={90} thumbnail />;
                            })}</div>}
                        </Form.Group> */}
                        <Button
                            size='sm'
                            type='submit'
                            className='btn btn-sm btn-success'
                            disabled={
                                submitting || !valid || isDouble || holdSubmit
                            }
                        >
                            <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                        </Button>
                    </Container>
                </form>
            )}
        />
    );
};

export default RSUBCreateForm;
