import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import OverlayingPopup from '~/src/components/Common/ModalUI/OverlayingPopup';
import { selectUsersPA, updateSocial } from '~/src/store/paSlice';
import { Field, Form as FinalForm } from 'react-final-form';
import InputField from '~/src/components/Common/Form/InputField';
import { ISocials } from '~/src/service/social';
import Select from 'react-select';

const UpdateSocial = ({ item }: {
    item: ISocials
}) => {
    const dispatch = useDispatch();

    const { socialUnload } = useSelector(selectUsersPA);
    const [isOpened, setIsOpened] = useState(false);

    const close = () => {
        setIsOpened(false);
    };

    const handleSubmit = (values: Record<string, any>) => {
        dispatch(updateSocial(item.id, {
            socialId: values.id.value,
            data: values.data,
        }));
        close();
    };

    return (
        <>
            <div style={{
                position: 'absolute',
                top: '-5px',
                right: '25px',
                cursor: 'pointer'
            }}
                onClick={() => setIsOpened(true)}
                title='Изменить'
            >
                <FontAwesomeIcon icon={['fas', 'pen']} style={{ fontSize: '10px' }} />
            </div>
            {
                isOpened && <OverlayingPopup onClose={close} isOpened={isOpened}>
                    <div className='main_popup' style={{ width: '800px' }}>
                        <p>Изменить</p>
                        <FinalForm
                            onSubmit={handleSubmit}
                            mutators={{
                                setValue: ([field, value], state, { changeValue }) => {
                                    changeValue(state, field, () => value);
                                },
                            }}
                            initialValues={{
                                id: item.social.id,
                                data: item.data,
                            }}
                            validate={() => {
                                const errors: any = {};
                                return errors;
                            }}
                            render={({ handleSubmit, submitting, valid, form }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name='id'>
                                        {({ input }) => (
                                            <Form.Group className='mb-2'>
                                                <Form.Label>
                                                    <>Мессенджер</>
                                                </Form.Label>
                                                <Select
                                                    {...input}
                                                    placeholder='Выберите мессенджер'
                                                    onChange={(e) =>
                                                        form.mutators.setValue(
                                                            'id',
                                                            e,
                                                        )
                                                    }
                                                    value={socialUnload?.find((e) => e.value == form.getState().values.id)}
                                                    options={socialUnload}
                                                />
                                            </Form.Group>
                                        )}
                                    </Field>
                                    <InputField
                                        label='Контакты'
                                        name='data'
                                        type='text'
                                    />
                                    <Button
                                        size='sm'
                                        type='submit'
                                        variant='success'
                                        disabled={submitting || !valid}
                                        className='mt-3'
                                    >
                                        Сохранить
                                    </Button>
                                </form>
                            )}
                        />
                    </div>
                </OverlayingPopup>
            }
        </>
    );
};

export default UpdateSocial;
