import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Badge, ButtonGroup } from 'react-bootstrap';
import { IDeveloper, IListEstateDev } from '~/src/service/developer/developer';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ListRow: FC<{ itemDevelop: IDeveloper, item: IListEstateDev }> = ({ itemDevelop, item }) => {
    return (
        <React.Fragment>
            <tr>
                <td>{itemDevelop.id}</td>
                <td style={{
                    maxWidth: '200px'
                }}>
                    {
                        itemDevelop.siteLink
                            ? <a href={itemDevelop.siteLink} target='_blank' rel='noopener noreferrer'>
                                {itemDevelop?.title}
                            </a>
                            : itemDevelop?.title
                    }
                    {itemDevelop?.isCrucialPartner == 1 && (
                        <>
                            <br />
                            <Badge className='bg-success'>Ключевой партнер</Badge>
                        </>
                    )}
                    {
                        (itemDevelop?.projects.length > 0 || itemDevelop?.isSuburban) &&
                        <>
                            <br />
                            <Badge className='bg-primary'>Подрядчик</Badge>
                        </>
                    }
                </td>
                <td>
                    {
                        item.siteUrl
                            ? <a href={item.siteUrl} target='_blank' rel='noopener noreferrer'>
                                {item?.title}
                            </a>
                            : item?.title
                    }
                </td>
                <td>
                    {
                        item?.reward
                    }
                </td>
                <td>
                    {
                        item?.subsidizedRate
                            ? 'Да '
                            : 'Нет '
                    }
                    {
                        item?.subsidizedRateDescr &&
                        <FontAwesomeIcon
                            icon={['fas', 'question-circle']}
                            title={item?.subsidizedRateDescr}
                            style={{ fontSize: '14px' }}
                        />
                    }
                </td>
                <td>
                    {
                        item?.installment
                            ? 'Да '
                            : 'Нет '
                    }
                    {
                        item?.installmentDescr &&
                        <FontAwesomeIcon
                            icon={['fas', 'question-circle']}
                            title={item?.installmentDescr}
                            style={{ fontSize: '14px' }}
                        />
                    }
                </td>
                <td>
                    {
                        item?.buyWoInitialPay
                            ? 'Да'
                            : 'Нет'
                    }
                </td>
                <td>
                    {
                        item?.isRennovationFlats
                            ? 'Да '
                            : 'Нет '
                    }
                    {
                        item?.rennovationFlatsDescr &&
                        <FontAwesomeIcon
                            icon={['fas', 'question-circle']}
                            title={item?.rennovationFlatsDescr}
                            style={{ fontSize: '14px' }}
                        />
                    }
                </td>
                <td>
                    {
                        itemDevelop?.fixAgentRules
                            ? 'Условия '
                            : 'Нет'
                    }
                    {
                        itemDevelop?.fixAgentRules &&
                        <FontAwesomeIcon
                            icon={['fas', 'question-circle']}
                            title={itemDevelop?.fixAgentRules}
                            style={{ fontSize: '14px' }}
                        />
                    }
                </td>
                <td>
                    {
                        itemDevelop?.isExchangeProgramm
                            ? 'Да'
                            : 'Нет'
                    }
                </td>
                <td>
                    {
                        itemDevelop?.chatLink
                            ? <Link
                                to={itemDevelop?.chatLink}
                                className='btn btn-sm btn-success'
                                title='Чат'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                Чат
                            </Link>
                            : 'Нет'
                    }
                </td>
                {/* <td>
                    {itemDevelop.contactPhone && (
                        <p>
                            <strong>Телефон основной:</strong>
                            <br />
                            <span>{phoneFormatter(itemDevelop.contactPhone)}</span>
                        </p>
                    )}
                    {itemDevelop.email && (
                        <p>
                            <strong>Email:</strong>
                            <br />
                            <span>{itemDevelop.email}</span>
                        </p>
                    )}
                    {itemDevelop.contacts?.map((contact) => {
                        return (
                            <React.Fragment key={contact.id}>
                                {contact.note && <p>{contact.note}</p>}
                                {contact.phone && (
                                    <p>{phoneFormatter(`${contact.phone}`)}</p>
                                )}
                                {contact.email && <p>{contact.email}</p>}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </td> */}
                <td>
                    <ButtonGroup vertical>
                        <Link
                            to={`/developer/update/${itemDevelop.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            Редактировать
                        </Link>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
