import { Link } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSyntheticEvent, useCallback, useState } from 'react';
import { redirect } from '~/src/store/appSlice';
import { selectDeveloper, goTo, all } from '~/src/store/developer/developerSlice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import ListRow from './ListRow';
import QuickSearch from './QuickSearchList';
import Checkbox from '../Common/FormFilter/Checkbox';
import React from 'react';

const HousingEstateListView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search } = useSelector(selectDeveloper);

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        dispatch(goTo({
            [e.target.name]: query.get(e.target.name) ? null : e.target.value
        }));
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/developer/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                    </Link>
                </ButtonGroup>
                <ButtonGroup>
                    <Button size='sm' type='button' variant='outline-secondary'>
                        <FontAwesomeIcon icon={['fas', 'eye']} /> Форма поиска
                    </Button>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/developer'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'id_asc'}
                        onChange={sortHandler}
                    >
                        <option value='reward_asc'>Комиссия &uarr;</option>
                        <option value='reward_desc'>Комиссия &darr;</option>
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <QuickSearch />
                </Col>
            </Row>
            <Row className='d-flex flex gap-5'>
                <Col>
                    <Checkbox
                        name='subsidizedRate'
                        label='Субсидированная ставка'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('subsidizedRate')}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name='installment'
                        label='Рассрочка'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('installment')}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name='buyWoInitialPay'
                        label='Без ПВ'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('buyWoInitialPay')}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name='isRennovationFlats'
                        label='Ремонт'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('isRennovationFlats')}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name='isExchangeProgramm'
                        label='Обмен'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('isExchangeProgramm')}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name='isSuburban'
                        label='Подрядчик'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('isSuburban')}
                    />
                </Col>
                <Col>
                    <Checkbox
                        name='saleStart'
                        label='Старт продаж'
                        onChange={handleChangeCheckboxValue}
                        checked={!!query.get('saleStart')}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={8}></Col>
                <Col xs={4}>
                    <div className='mb-2'>
                        <Form.Label></Form.Label>
                    </div>
                    <Form.Label>
                        <strong>Всего объектов:</strong> {items.count}
                    </Form.Label>
                </Col>
            </Row>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Название</td>
                        <td>Проект</td>
                        <td>Комиссия</td>
                        <td>Субсидированная ставка</td>
                        <td>Рассрочка</td>
                        <td>Без ПВ</td>
                        <td>Ремонт</td>
                        <td>Закрепление</td>
                        <td>Обмен</td>
                        <td>Контакты</td>
                        <td>Управление  </td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => <React.Fragment key={item.id}>
                            {
                                item.estates.length > 0 &&
                                item.estates.map((estate) => <ListRow key={`develop${item.id}_estate${estate.id}`} itemDevelop={item} item={estate} />)
                            }
                            {
                                (query.get('subsidizedRate') || query.get('installment') ||
                                    query.get('buyWoInitialPay') || query.get('isRennovationFlats') ||
                                    query.get('saleStart') || query.get('sort'))
                                    ? null
                                    : item.cv.length > 0 &&
                                    item.cv.map((itemCv) => <ListRow key={`develop${item.id}_cv${itemCv.id}`} itemDevelop={item} item={itemCv} />)
                            }
                            {/* {
                                item.estates.length > 0 &&
                                item.estates.map((estate) => <ListRow key={item.id} item={item} />)
                            } */}
                        </React.Fragment>)
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default HousingEstateListView;
