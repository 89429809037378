import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Row, Col, Card, Container, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import OverlayingPopup from '~/src/components/Common/ModalUI/OverlayingPopup';
import { createSocial, deleteSocial, selectUsersPA } from '~/src/store/paSlice';
import { Field, Form as FinalForm } from 'react-final-form';
import InputField from '~/src/components/Common/Form/InputField';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import UpdateSocial from './Control/UpdateSocial';

const Social = () => {
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState(false);
    const { item, socialUnload } = useSelector(selectUsersPA);
    const base = 'https://files.citidom.com/';

    const close = () => {
        setIsOpened(false);
    };
    const handleSubmit = (values: Record<string, any>) => {
        const data = {
            id: values.id.value,
            data: values.data,
        };
        dispatch(createSocial(data.id, data.data));
        setIsOpened(false);
    };

    const removeSocial = (id: number) => {
        dispatch(deleteSocial(id));
    };

    return (
        <>
            <h5>Мессенджеры для связи</h5>
            <Container fluid>
                <Row className='overflow-auto flex-nowrap' style={{ whiteSpace: 'nowrap', padding: '10px 0px' }}>
                    <Col xs='auto' style={{ width: '100px', height: '100px' }}>
                        <Card
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                height: '100%',
                                cursor: 'pointer',
                                textAlign: 'center',
                                fontSize: '20px'
                            }}
                            onClick={() => setIsOpened(true)}
                        >
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                        </Card>
                    </Col>
                    {item?.socials?.map((item) => (
                        <Col key={item.id} xs='auto' style={{ width: '100px', height: '100px' }}>
                            <Card style={{
                                height: '100px',
                                width: '100px',
                                padding: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <UpdateSocial item={item} />
                                <div style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '5px',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => removeSocial(item.id)}
                                >
                                    <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '13px' }} />
                                </div>
                                <Card.Img src={`${base}${item?.social?.icon}`} alt={item?.social?.title}
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        objectFit: 'cover',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} />
                                <Card.Body style={{
                                    padding: '10px',
                                    height: '20px',
                                    flex: 'none',
                                }}>
                                    <Card.Text style={{
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                        fontSize: '11px',
                                        display: 'flex',
                                        gap: '5px',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <div>
                                            {item?.social?.title}
                                        </div>
                                        <div>
                                            {
                                                item?.data.length < 12
                                                    ? item?.data
                                                    : `${item?.data.slice(0, 11)}...`
                                            }
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

            {/* Модальное окно с фомой для создания записи */}
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup' style={{ width: '800px' }}>
                    <p>Добавить</p>
                    <FinalForm
                        onSubmit={handleSubmit}
                        mutators={{
                            setValue: ([field, value], state, { changeValue }) => {
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={() => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid, form }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name='id'>
                                    {({ input }) => (
                                        <Form.Group className='mb-2'>
                                            <Form.Label>
                                                <>Мессенджер</>
                                            </Form.Label>
                                            <Select
                                                {...input}
                                                placeholder='Выберите мессенджер'
                                                onChange={(e) =>
                                                    form.mutators.setValue(
                                                        'id',
                                                        e,
                                                    )
                                                }
                                                options={socialUnload}
                                            />
                                        </Form.Group>
                                    )}
                                </Field>
                                <InputField
                                    label='Контакты'
                                    name='data'
                                    type='text'
                                />
                                <Button
                                    size='sm'
                                    type='submit'
                                    variant='success'
                                    disabled={submitting || !valid}
                                    className='mt-3'
                                >
                                    Сохранить
                                </Button>
                            </form>
                        )}
                    />
                </div>
            </OverlayingPopup>
        </>
    );
};

export default Social;
