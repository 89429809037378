import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';
import SaleView from '~/src/components/Sale';
import { checkMegasale, allMegasaleRealty } from '~/src/store/megasaleSlice';
import { useQuery } from '~/src/helpers/hooks';

const SalePage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('SALE'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(checkMegasale());
    }, [dispatch]);

    useEffect(() => {
        dispatch(allMegasaleRealty(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    return <SaleView />;
};

export default SalePage;
