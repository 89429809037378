import { IUser } from '../user';
import Api from '~/src/helpers/http-common';
import { IResponseListData, IUpdateData } from '../../interfaces/common';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IDeveloper>> => {
    return await Api.get(
        `/developer?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IDeveloper,
    files?: Record<string, FileList>,
): Promise<IDeveloper | any> => {
    return (await Api.post<IDeveloper>(
        '/developer',
        data,
        files,
    )) as IDeveloper;
};

export const read = async (id: number): Promise<IDeveloper> => {
    return await Api.get(`/developer/${id}`);
};

export const update = async (
    id: string | number,
    data: IDeveloper,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IDeveloper>> => {
    return await Api.put<IUpdateData<IDeveloper>>(
        `/developer/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/developer/${id}`);
};

export const unload = async (
    queryParams?: URLSearchParams,
): Promise<Partial<IDeveloper>> => {
    return await Api.get(
        `/developer/unload?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export interface IDeveloper {
    id: number;
    title: string;
    logotype?: string;

    director?: IUser;
    phone?: number;
    email?: string;
    website?: string;
    description?: string;
    contactPhone?: string;
    isCrucialPartner?: any;
    contacts?: IDeveloperADContacts[];
    projects?: IListDev[];
    cv: IListDev[];
    estates: IListEstateDev[];

    isSuburban?: boolean;

    chatLink?: string;
    siteLink?: string;
    fixAgentRules?: string;
    isExchangeProgramm?: boolean;
    exchangeProgrammDescription?: string;
}

export interface IDeveloperADContacts {
    id: number;
    developerId?: number;
    email?: string;
    phoneCountryCode?: number;
    phone?: number;
    note?: string;
}

export interface IListEstateDev {
    id?: number,
    title?: string,
    reward?: number,
    subsidizedRate?: boolean,
    subsidizedRateDescr?: string,
    installment?: boolean,
    installmentDescr?: string,
    buyWoInitialPay?: boolean,
    saleDepPhone?: string,
    isRennovationFlats?: boolean,
    rennovationFlatsDescr?: string,
    siteUrl?: string;
}

interface IListDev {
    id: number;
    title: string;
}
