import { useNavigate } from 'react-router-dom';
import InputField from '../Common/Form/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { create, update, selectDeveloper } from '~/src/store/developer/developerSlice';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import {
    required,
} from '~/src/helpers/validators';
import { Button, ButtonGroup, Container, Form, FormGroup, Navbar } from 'react-bootstrap';
import arrayMutators from 'final-form-arrays';
import CheckboxField from '../Common/Form/CheckboxField';

export default function UpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item } = useSelector(selectDeveloper);

    const onSubmit = (values: Record<string, any>) => {
        if (item?.id === undefined) {
            dispatch(create({ ...values }));
        } else {
            dispatch(update(+values.id, { ...values }));
        }
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                ...createInitialFormValues(item),
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                ...arrayMutators,
            }}
            validate={() => {
                const errors: any = {};
                return errors;
            }}
            render={({ handleSubmit, submitting, valid, form }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <InputField
                            name='title'
                            label='Название компании'
                            validators={required}
                        />
                        <FormGroup className='d-flex gap-3'>
                            <CheckboxField
                                name='isSuburban'
                                label='Строит загородку'
                            />
                            <CheckboxField
                                name='isCrucialPartner'
                                label='Ключевой партнер'
                            />
                        </FormGroup>
                        <InputField
                            name='chatLink'
                            label='Ссылка на чат с застройщиком'
                        />
                        <InputField
                            name='siteLink'
                            label='Ссылка на сайт застройщика с информацией о застройщике'
                        />
                        <Field name='fixAgentRules'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Правила закрепления клиента</Form.Label>
                                    <Form.Control
                                        {...input}
                                        as='textarea'
                                        rows={5}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Правила закрепления клиента'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        <FormGroup className='d-flex gap-3'>
                            <CheckboxField
                                name='isExchangeProgramm'
                                label='Работает с программой обмен'
                            />
                        </FormGroup>
                        {
                            !!form.getState().values.isExchangeProgramm &&
                            <Field name='exchangeProgrammDescription'>
                                {({ input, meta }) => (
                                    <Form.Group className='mb-3'>
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                            {...input}
                                            as='textarea'
                                            rows={5}
                                            size='sm'
                                            isInvalid={
                                                meta.error || meta.submitError
                                            }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {meta.error || meta.submitError}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                        }
                    </Container>
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
}
