import { Slice, createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { IResponseListData } from '../../interfaces/common';
import { IFilestore } from '../../service/filestore';
import * as api from '~/src/service/realty.suburban';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import { successNotify } from '../../helpers/createNotify';
import { ICottageVillage } from '../../service/cottageVillage/cottage.village.service';
import { IAddressPlaceDetail } from '../../service/address';
import { IUser } from '../../service/user';
import { IMegasaleInfoMegasale, IMegaSaleRealty } from '../megasaleSlice';
import { IAgency } from '~/src/service/agency';
import { IUnloadDirection } from '~/src/service/direction';
import { IXmlFeedRealty } from '~/src/service/xml.feed';

export const sliceName = 'realtySuburban';

interface IRSUBSlice {
    items: IResponseListData<IRSUBModel>;
    itemsOut: number;
    item?: IRSUBModel;
    updating?: IRSUBModel;
    itemPhotos?: IFilestore[];
    mapItems?: IRSUBMapModel[];
    search?: IRSUBModelSearch;
    errors?: any;
    loading: boolean;
    navLink: string;
    baseLink: string;
    currentIndex: number;
    countMyOutDatedSoon: number;
}

const initialState = {
    items: {},
    itemsOut: null,
    mapItems: [],
    search: {
        sort: 'created_at_desc',
        limit: 25,
        page: 1,
        own: 'all',
    },
    loading: true,
    navLink: '/realty-suburban',
    baseLink: '/realty-suburban',
    errors: {},
    currentIndex: null,
    countMyOutDatedSoon: null,
};
const RSUBSlice: Slice<IRSUBSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IRSUBSlice,
            action: TAnyAction<IResponseListData<IRSUBModel>>,
        ) => {
            state.items = action.payload;
        },
        setAllOut: (state: IRSUBSlice, action: TAnyAction<number>) => {
            state.itemsOut = action.payload;
        },
        setMapItems: (
            state: IRSUBSlice,
            action: TAnyAction<IRSUBMapModel[]>,
        ) => {
            state.mapItems = action.payload;
        },
        set: (state: IRSUBSlice, action: TAnyAction<IRSUBModel>) => {
            state.item = action.payload;
            state.updating = action.payload;
            state.itemPhotos = state.item.photos;
        },
        setUpdating: (state: IRSUBSlice, action: TAnyAction<IRSUBModel>) => {
            state.updating = action.payload;
        },
        setPhotos: (state: IRSUBSlice, action: TAnyAction<IFilestore[]>) => {
            state.itemPhotos = action.payload;
        },
        setErrors: (state: IRSUBSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (
            state: IRSUBSlice,
            action: TAnyAction<IRSUBModelSearch>,
        ) => {
            state.search = action.payload;
        },
        setNavLink: (state: IRSUBSlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
        setLoading: (state: IRSUBSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
        setCurrenIndex: (state: IRSUBSlice, action: TAnyAction<number>) => {
            state.currentIndex = action.payload;
        },
        setCountMyOutDatedSoon: (
            state: IRSUBSlice,
            action: TAnyAction<number>,
        ) => {
            state.countMyOutDatedSoon = action.payload;
        },
    },
});

export const {
    set,
    setAll,
    setSearch,
    setErrors,
    setPhotos,
    setAllOut,
    setNavLink,
    setMapItems,
    setUpdating,
    setCurrenIndex,
    setChosenMapItems,
    setCountMyOutDatedSoon,
} = RSUBSlice.actions;

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().realtySuburban.search, ...params };
        dispatch(
            redirect(
                `/realty-suburban?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        dispatch(setAppLoading(true));
        const items = await api.all(queryParams);
        const obj = {};
        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }
        dispatch(setSearch(obj));
        dispatch(setNavLink(`/realty-suburban?${queryParams.toString()}`));
        dispatch(setAll(items));
        dispatch(setAppLoading(false));
        return items;
    };
};

export const create = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.create(data, files);
        dispatch(setAppLoading(false));
        if (item) {
            dispatch(redirect(getState().realtySuburban.navLink));
            dispatch(redirect(`/realty-suburban/edit/${item.id}`));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.read(id);
        dispatch(set(item));
        dispatch(setAppLoading(false));
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        dispatch(setUpdating(data));
        const response = await api.update(id, data, files);
        if (response.success) {
            dispatch(set(response.data));
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const uploadPhoto = ({
    id,
    files,
    onUploadProgress,
}: api.UploadProps) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.uploadPhotos(id, files, {
            onUploadProgress: onUploadProgress,
        });

        console.log('upload response');
        console.dir(response);

        if (response && Array.isArray(response)) {
            if (getState().realtySuburban.item.id === id) {
                dispatch(
                    setPhotos([
                        ...getState().realtySuburban.itemPhotos,
                        ...response,
                    ]),
                );
            }

            successNotify({
                message: `Загружено ${response.length} фото`,
                title: 'Успешно!',
            });
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.remove(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburban.items,
                    items: getState().realtySuburban.items.items.filter(
                        (item: IRSUBModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const deleteFile = (id: number, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.deleteFile(id, name);
        if (response) {
            dispatch(
                setPhotos([
                    ...getState()
                        .realtySuburban.itemPhotos.filter(
                            (photo: IFilestore) => photo.name !== name,
                        )
                        .sort(
                            (a: IFilestore, b: IFilestore) => a.queue - b.queue,
                        ),
                ]),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const setSold = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.sold(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburban.items,
                    items: getState().realtySuburban.items.items.filter(
                        (item: IRSUBModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const setSoldOff = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.soldOff(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburban.items,
                    items: getState().realtySuburban.items.items.filter(
                        (item: IRSUBModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const transfer = (id: number, uid: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.transfer(id, uid);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburban.items,
                    items: getState().realtySuburban.items.items.map(
                        (item: IRSUBModel) => {
                            if (item.id === id) {
                                return { ...response.data };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().realtySuburban.item?.id == id) {
                dispatch(set(response.data));
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const getAllMap = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<IRSUBMapModel[]> => {
        queryParams = queryParams || new URLSearchParams();
        const items = await api.allCoordinates(queryParams);
        if (items) {
            dispatch(setMapItems(items));
            return items;
        } else {
            dispatch(setErrors('Ошибка при получении координат'));
        }
    };
};

export const favorite = (id: number | string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.favorite(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburban.items,
                    items: getState().realtySuburban.items?.items?.map(
                        (item: IRSUBModel) => {
                            if (item.id === id) {
                                return {
                                    ...item,
                                    isFavorite: !item.isFavorite,
                                };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().realtySuburban.item) {
                dispatch(
                    set({
                        ...getState().realtySuburban.item,
                        isFavorite: !getState().realtySuburban.item.isFavorite,
                    }),
                );
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const xmlfeedaction = (id: number, data: Record<string, boolean>) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.xmlfeedaction(id, data);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburban.items,
                    items: getState().realtySuburban.items.items.map(
                        (item: IRSUBModel) => {
                            if (item.id === id) {
                                return {
                                    ...item,
                                    xmlfeedunload: response.data,
                                };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().realtySuburban.item?.id == id) {
                dispatch(
                    set({
                        ...getState().realtySuburban.item,
                        xmlfeedunload: response.data,
                    }),
                );
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const fresh = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.fresh(id);
    };
};

export const outDateSoon = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.countmyoutdatedsoon();
        if (response) {
            // dispatch(setCountMyOutDatedSoon(response));
        }
    };
};

export const removeSuburbanMegasaleInfo = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(
            setAll({
                ...getState().realtySuburban.items,
                items: getState().realtySuburban.items?.items?.map(
                    (item: IRSUBModel) => {
                        if (item.id === id) {
                            return { ...item, megasale: null };
                        }
                        return item;
                    },
                ),
            }),
        );
        if (getState().realtySuburban.item) {
            dispatch(
                set({
                    ...getState().realtySuburban.item,
                    megasale: null,
                }),
            );
        }
    };
};

export const addSuburbanMegasaleInfo = (
    id: number | string,
    megasale: IMegasaleInfoMegasale,
) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(
            setAll({
                ...getState().realtySuburban.items,
                items: getState().realtySuburban.items?.items?.map(
                    (item: IRSUBModel) => {
                        if (item.id === id) {
                            return { ...item, megasale: megasale };
                        }
                        return item;
                    },
                ),
            }),
        );
        if (getState().realtySuburban.item) {
            dispatch(
                set({
                    ...getState().realtySuburban.item,
                    megasale: megasale,
                }),
            );
        }
    };
};

export const selectSUB = (state: TState): IRSUBSlice => state.realtySuburban;

export default RSUBSlice.reducer;

export interface IRSUBMapModel {
    id?: number;
    title?: string;
    price?: number;
    latitude?: number;
    longitude?: number;
}

export interface IRSUBModel {
    id: number;
    isPublic: boolean;

    sold: boolean;
    cottageVillageId: number;
    cn: string;
    type: string;
    addressPlaceDetailId: number;
    price: number;
    isExclusive: boolean;
    sellerName: string;
    sellerPhone: number;
    description: string;
    privateNote: string;
    area: number;
    houseSpaceTotal: number;
    houseStoreys: number;
    houseRooms: number;
    houseMaterial: string;
    videoUrl?: string;
    renovation: Record<string, string>[];
    gas: boolean;
    fence: boolean;
    electricity: boolean;
    heating: Record<string, string>[];
    waterSuply: Record<string, string>[];
    landAppointment: Record<string, string>[];
    sewage: Record<string, string>[]; // Enum: Array [3]
    isClosedArea: boolean;
    isProtectedArea: boolean;
    hasSportGround: boolean;
    hasBesideSchool: boolean;
    hasBesidePreSchool: boolean;
    hasBesidePark: boolean;
    hasBesideWater: boolean;
    hasBesideForest: boolean;
    isEspecial?: boolean;
    isDiscount?: boolean;
    ruralMortgage?: boolean;

    garage?: string;
    sauna?: boolean;
    carport?: boolean;
    sellerMinors?: number;


    terrace?: boolean;
    basement?: boolean;
    fromLegalEntity?: boolean;

    cottageVillage: ICottageVillage;
    addressDetail: IAddressPlaceDetail;
    agentInfo: IUser;
    freshAgo: number;
    freshAgoH: number;
    photos: IFilestore[] | [];
    direction: IUnloadDirection;


    fullfiedFirstAt?: Date | null;

    isUnderstatementNeed?: boolean;
    understatementSum?: number;
    bankEncumbrance?: number;
    bankEncumbranceTitle?: string;

    isFavorite?: boolean;
    priceRise?: boolean;

    isFullfied?: boolean;
    isMy?: boolean;

    isFullfiedButPhoto?: any;
    photoCount?: any;

    megasale?: IMegaSaleRealty;

    xmlfeedunload?: IXmlFeedRealty[];
    priceHistory?: IRealtySuburbanPrice[];

    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
    freshAt?: Date;
    freshBy?: string;
    deletedAt?: Date | null;
    deletedBy?: string | null;

    verified?: boolean;
    verifiedAt?: Date | null;
    verifiedBy?: any;
}

export interface IRSUBModelSearch {
    page?: number;
    limit?: number;
    sort?: string;
    my?: boolean;
    cn?: string;
    priceMin?: number;
    priceMax?: number;
    isExclusive?: any;
    isDiscount?: any;
    onMegasale?: any;
    fromLegalEntity?: any;
    ruralMortgage?: any;

    areaMin?: number;
    areaMax?: number;
    houseSpaceTotalMin?: number;
    houseSpaceTotalMax?: number;
    houseStoreysMin?: number;
    houseStoreysMax?: number;
    houseRoomsMin?: number;
    houseRoomsMax?: number;
    houseMaterial?: string[]; // Available values: log, metal, brick, timber, foam_block, gas_blocks, experimental, sandwich_panel, reinforced_concrete_panel
    renovation?: string[]; // Available values: euro, design, standard, whiteBox, noDecoration, fineFinishing, withoutFinishing
    gas?: any;
    fence?: boolean;
    electricity?: any;
    heating?: string; // Available values: gas, stove, central, electricity
    waterSuply?: string; // Available values: well, chink, central
    landAppointment?: string; // Available values: gardening, private_household, individual_housing_construction
    sewage?: string; // Available values: no, central, septic_tank
    isClosedArea?: boolean;
    isProtectedArea?: boolean;
    hasSportGround?: boolean;
    hasBesideSchool?: boolean;
    hasBesidePreSchool?: boolean;
    hasBesidePark?: boolean;
    hasBesideWater?: boolean;
    hasBesideForest?: boolean;
    sold?: boolean;
}


export interface IRealtySuburbanPrice {
    id: number;
    realtySuburbanId: number;
    oldPrice: number;
    newPrice: number;
    rise: boolean;
    createdAt: Date;
    createdBy?: string;
    userCreatedBy?: IUser;
}
