import { Link } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import classNames from 'classnames';
import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';
import {
    fieldsHouse,
    fieldsLand,
} from '~/src/common/data/realtySuburban/realty.suburban.translate';
import {
    IMegaSaleRealtySuburban,
    removeRealtyMegasale,
    verifyRealtyMegasale,
} from '~/src/store/megasaleSlice';
import { useDispatch } from 'react-redux';
import IListPriceRise from './IListPriceRise';
import RSUBListPhotos from '../../Realty/Suburban/IList/RSUBListPhotos';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';

const ListSuburbanRealty: FC<TListProps> = ({ item }) => {

    const [field, setFied] = useState([]);

    const dispatch = useDispatch();
    const price = new Intl.NumberFormat('ru-RU').format(+item.realty?.price);
    const ppriceMegaSale = new Intl.NumberFormat('ru-RU').format(+item?.price);
    const discount = new Intl.NumberFormat('ru-RU').format(
        +item.realty?.price - +item?.price,
    );

    const handleVerifyMegasaleRealty = () => {
        dispatch(verifyRealtyMegasale(item.id));
    };
    const handleRejectVerifyMegasaleRealty = () => {
        dispatch(removeRealtyMegasale(item.id));
    };

    const handleRemoveRealtyMegasale = () => {
        dispatch(removeRealtyMegasale(item.id));
    };

    const fullfiedStatus = (item: IRSUBModel, obj) => {
        const result = [];
        obj.forEach((field) => {
            const value = item[field.name];
            if (value === null || (Array.isArray(value) && value.length < 3)) {
                result.push({ translate: field.translate, status: 'Нет' });
            } else {
                result.push({ translate: field.translate, status: 'Да' });
            }
        });
        return result;
    };

    useEffect(() => {
        if (item.realty.type == 'land') {
            setFied(fullfiedStatus(item.realty, fieldsLand));
        } else {
            setFied(fullfiedStatus(item.realty, fieldsHouse));
        }
    }, [item]);

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={'/sale'}>{item.realty?.id}</Link>
                    {item.realty?.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {!item.realty?.isFullfied && (
                        <React.Fragment>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                title={field
                                    ?.map((field) => `${field.translate}: ${field.status}`)
                                    .join('\n')}
                            />
                        </React.Fragment>
                    )}
                    {item.realty?.xmlfeedunload &&
                        item.realty?.xmlfeedunload.length > 0 && (
                            <React.Fragment>
                                <br />
                                {item.realty?.xmlfeedunload.map((feed) => {
                                    if (feed.enabled) {
                                        switch (feed.feed?.type) {
                                            case 'avito':
                                                return (
                                                    <p>
                                                        <img
                                                            src={avitoLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'cian':
                                                return (
                                                    <p>
                                                        <img
                                                            src={cianLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'domclick':
                                                return (
                                                    <p>
                                                        <img
                                                            src={domclickLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'yandex':
                                                return (
                                                    <p>
                                                        <img
                                                            src={yandexLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                        }
                                    }
                                })}
                            </React.Fragment>
                        )}
                    {item.realty?.isExclusive && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Эксклюзивный</Badge>
                        </React.Fragment>
                    )}
                    {item.realty?.isDiscount && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Промо</Badge>
                        </React.Fragment>
                    )}
                    {item.isLiquid && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Ликвид</Badge>
                        </React.Fragment>
                    )}
                </td>
                <td>
                    <RSUBListPhotos photos={item.realty?.photos} />
                    {item.realty?.sold && <Badge bg='danger'>В архиве</Badge>}
                    {item?.realty?.videoUrl &&
                        <Link
                            to={item?.realty?.videoUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-sm btn-success'
                            title='Видео'
                        >
                            <FontAwesomeIcon icon={['fas', 'video']} />{' '}
                            Видео
                        </Link>
                    }
                </td>
                <td>
                    <p>
                        <strong>
                            <FormattedMessage
                                id={`realty_suburban_type_${item?.realty?.type}`}
                            />
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage
                                id={`realty_suburban_landAppointment_${item?.realty?.landAppointment}`}
                            />
                        </strong>
                    </p>
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                        // onClick={() => ()}
                        >
                            {item?.realty?.addressDetail?.formattedAddress}
                        </Button>
                    </p>
                    {item?.realty?.cottageVillage && (
                        <p>
                            <strong>Коттеджный поселок</strong>
                            <br />
                            <span>{item?.realty?.cottageVillage?.title}</span>
                        </p>
                    )}
                    {item?.realty?.direction && (
                        <p>
                            <strong>Тракт: </strong>
                            <span>{item?.realty?.direction?.title}</span>
                        </p>
                    )}
                    {item?.realty?.cn && (
                        <p>
                            <strong>Кадастровый номер:</strong>
                            <br />
                            {item?.realty?.cn}
                        </p>
                    )}
                </td>
                <td>
                    {item?.realty?.area && (
                        <p>
                            <strong>Площадь участка: </strong>
                            {item?.realty?.area}
                        </p>
                    )}
                    {item?.realty?.houseSpaceTotal && (
                        <p>
                            <strong>Площадь дома: </strong>
                            {item?.realty?.houseSpaceTotal}
                        </p>
                    )}
                    {item?.realty?.gas && (
                        <p>
                            <strong>Газ</strong>
                        </p>
                    )}
                    {item?.realty?.electricity && (
                        <p>
                            <strong>Электричество</strong>
                        </p>
                    )}
                    {item?.realty?.waterSuply && (
                        <p>
                            <strong>Водоснабжение</strong>
                            <br></br>
                            <span>
                                <FormattedMessage
                                    id={`realty_suburban_waterSuply_${item?.realty?.waterSuply}`}
                                />
                            </span>
                        </p>
                    )}
                </td>
                <td>
                    {
                        item?.realty?.type == 'land'
                            ? '-----'
                            : <>
                                {item?.realty?.houseMaterial && (
                                    <p>
                                        <strong>Материал дома</strong>
                                        <br />
                                        <FormattedMessage
                                            id={`house_material_${item?.realty?.houseMaterial}`}
                                        />
                                    </p>
                                )}
                                {item?.realty?.houseStoreys && (
                                    <p>
                                        <strong>Этажей в доме</strong>
                                        <br />
                                        {item?.realty?.houseStoreys}
                                    </p>
                                )}
                                {item?.realty?.houseRooms && (
                                    <p>
                                        <strong>Кол-во спальных комнат:</strong>
                                        {item?.realty?.houseRooms}
                                    </p>
                                )}
                                {item?.realty?.heating && (
                                    <p>
                                        <strong>Отопление</strong>
                                    </p>
                                )}
                                {item?.realty?.renovation && (
                                    <p>
                                        <strong>Ремонт</strong>
                                        <br />
                                        <FormattedMessage
                                            id={`realty_secondary_renovation_${item?.realty?.renovation}`}
                                        />
                                    </p>
                                )}
                            </>
                    }
                </td>
                <td
                    style={{
                        minWidth: '200px',
                    }}
                >
                    <p>
                        <strong style={{ color: 'red' }}>Цена</strong>
                        <br />
                        <span style={{ color: 'red' }}>{price}</span>
                        <br></br>
                        <strong style={{ color: 'green' }}>
                            Цена для распродажи
                        </strong>
                        <br />
                        <span style={{ color: 'green' }}>{ppriceMegaSale}</span>
                        <br></br>
                        <strong>Скидка</strong>
                        <br />
                        <span>{discount}</span>
                        <br></br>
                        {item.realty?.type !== 'land'
                            ? item.price &&
                            item.realty?.area &&
                            item.realty?.houseSpaceTotal && (
                                <>
                                    <strong>
                                        Цена за м<sup>2</sup> дома
                                    </strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price /
                                                +item.realty?.houseSpaceTotal,
                                            ),
                                        )}
                                    </span>
                                    <br></br>
                                </>
                            )
                            : item.price &&
                            item.realty?.area && (
                                <>
                                    <strong>Цена за сотку участка</strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price / +item.realty?.area,
                                            ),
                                        )}
                                    </span>
                                </>
                            )}
                        <br></br>
                        <IListPriceRise item={item} agent={item?.realty?.agentInfo} priceHistory={item.realty.priceHistory} />
                    </p>
                    {item.realty?.isUnderstatementNeed ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Занижение
                            </strong>
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.realty?.understatementSum,
                            )}
                            <br />
                        </p>
                    ) : null}
                    {+item.realty?.bankEncumbrance > 0 ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Обременение
                            </strong>
                            <br />
                            {item.realty?.bankEncumbranceTitle}
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.realty?.bankEncumbrance,
                            )}
                            <br />
                        </p>
                    ) : null}
                </td>
                <td>
                    {item.realty?.isMy ? (
                        <React.Fragment>
                            {item.realty?.sellerName && (
                                <p>
                                    <strong>Продавец</strong>
                                    <br />
                                    {item.realty?.sellerName}
                                </p>
                            )}

                            {item.realty?.sellerPhone && (
                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(
                                        `${item.realty?.sellerPhone}`,
                                    )}
                                </p>
                            )}
                        </React.Fragment>
                    ) : (
                        item.realty?.agentInfo && (
                            <React.Fragment>
                                {item.realty?.agentInfo?.agencyInfo?.agency && (
                                    <p>
                                        <strong>Агентство</strong>
                                        <br></br>
                                        <span>
                                            {
                                                item.realty?.agentInfo?.agencyInfo
                                                    ?.agency.title
                                            }
                                        </span>
                                    </p>
                                )}
                                <p>
                                    <strong>
                                        {item?.realty?.agentInfo?.role != 'agent'
                                            ? 'Собственник'
                                            : 'Агент'}
                                    </strong>
                                    <br />
                                    {item.realty?.agentInfo?.fullname}
                                </p>

                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(
                                        `${item.realty?.agentInfo?.phone}`,
                                    )}
                                </p>
                            </React.Fragment>
                        )
                    )}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Link
                            to={`/realty-suburban/card/${item.realty?.id}`}
                            className='btn btn-sm btn-primary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <ButtonGroup>
                            <Button
                                size='sm'
                                variant={
                                    item.isVerified ? 'success' : 'warning'
                                }
                                title='Верификация'
                                onClick={handleVerifyMegasaleRealty}
                            >
                                {item.isVerified
                                    ? 'Объект прошел модерацию'
                                    : 'Подтвердить объект'}
                            </Button>
                            {!item.isVerified && (
                                <Button
                                    size='sm'
                                    variant='danger'
                                    title='Верификация'
                                    onClick={handleRejectVerifyMegasaleRealty}
                                >
                                    Отклонить
                                </Button>
                            )}
                        </ButtonGroup>
                        <Button
                            size='sm'
                            variant='outline-danger'
                            title='Снять с распродажи'
                            onClick={handleRemoveRealtyMegasale}
                        >
                            Снять с распродажи
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

type TListProps = {
    item: IMegaSaleRealtySuburban;
};

export default ListSuburbanRealty;
