import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import moment from 'moment';
import { goTo, selectMegaSale } from '~/src/store/megasaleSlice';
import FormMegasaleStart from '~/src/components/Sale/FormMegaSaleStart';
import ListSecondaryRealty from '~/src/components/Sale/ListMegasale/ListSecondaryRealty';
import ListPrimaryRealty from './ListMegasale/ListPrimaryRealty';
import { useCallback } from 'react';
import Pagination from '../Common/Pagination';
import ListSuburbanRealty from './ListMegasale/ListSuburbanRealty';

const SaleView = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { megasaleActive, items, search } = useSelector(selectMegaSale);

    const limitHandler = useCallback(
        (e) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const verifiedHandler = useCallback(
        (e) => {
            dispatch(goTo({ verified: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <div style={{ height: '100vh' }}>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button size='sm' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
            </Navbar>
            {megasaleActive ? (
                <>
                    <Row>
                        <Col xs={4}>
                            <Col>На данный момент распродажа активна</Col>
                            <Col>Название: {megasaleActive?.title}</Col>
                        </Col>
                        <Col xs={3}>
                            Дата начала:{' '}
                            {moment
                                .utc(megasaleActive.dateStart)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            <br />
                            Дата конца:{' '}
                            {moment
                                .utc(megasaleActive.dateFinish)
                                .format('YYYY-MM-DD HH:mm:ss')}
                        </Col>
                    </Row>
                    <div>
                        <Row className='mb-2'>
                            <Col xs={4}>
                                <Form.Label>На странице</Form.Label>
                                <Form.Select
                                    className='form-control'
                                    size='sm'
                                    value={search?.limit || 25}
                                    onChange={limitHandler}
                                >
                                    <option value={1}>1</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={100}>100</option>
                                </Form.Select>
                            </Col>
                            <Col xs={4}>
                                <div className='mb-2'>
                                    <Form.Label></Form.Label>
                                </div>
                                <Form.Label>
                                    <strong>
                                        Всего объектов на распродаже:
                                    </strong>{' '}
                                    {items.count}
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col xs={4}>
                                <Form.Label>Требуют модерации</Form.Label>
                                <Form.Select
                                    className='form-control'
                                    size='sm'
                                    value={search?.verified}
                                    onChange={verifiedHandler}
                                >
                                    <option></option>
                                    <option value={1}>Нет</option>
                                    <option value={0}>Да</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        {items && items?.pagination?.have && (
                            <Pagination
                                pagination={items?.pagination}
                                onClick={handlePaginationClick}
                            />
                        )}
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Фото</td>
                                    <td>Адрес</td>
                                    <td>Параметры</td>
                                    <td>Дом</td>
                                    <td>Цена</td>
                                    <td>Продавец</td>
                                    <td>Управление</td>
                                </tr>
                            </thead>
                            <tbody>
                                {items && items.items && items.items.length > 0
                                    ? items.items.map((item) =>
                                        item.realtyType === 'secondary' ? (
                                            <ListSecondaryRealty
                                                key={item.id}
                                                item={item}
                                            />
                                        ) : item.realtyType === 'primary'
                                            ? <ListPrimaryRealty
                                                key={item.id}
                                                item={item}
                                            />
                                            : item.realtyType === 'suburban' &&
                                            <ListSuburbanRealty
                                                key={item.id}
                                                item={item}
                                            />)
                                    : null}
                            </tbody>
                        </Table>
                        <Row>
                            <Col>
                                {items && items.pagination?.have && (
                                    <Pagination
                                        pagination={items.pagination}
                                        onClick={handlePaginationClick}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </>
            ) : (
                <FormMegasaleStart />
            )}
        </div>
    );
};

export default SaleView;
