import { useDispatch, useSelector } from 'react-redux';
import React, { useState, BaseSyntheticEvent, useEffect } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    InputGroup,
    Row,
} from 'react-bootstrap';
import { useQuery } from '~/src/helpers/hooks';
import { selectAuth } from '~/src/store/authSlice';
import { selectAgency } from '~/src/store/agencySlice';
import Checkbox from '../../../Common/FormFilter/Checkbox';
import Select, { MultiValue } from 'react-select';
import { suburbanHeatingSearch } from '~/src/common/data/realtySuburban/realty.suburban.heating';
import { suburbanRenovationSearch } from '~/src/common/data/realtySuburban/realty.suburban.renovation';
import { suburbanMaterialSearch } from '~/src/common/data/suburban.material';
import { suburbanWaterSuplySearch } from '~/src/common/data/realtySuburban/realty.suburban.waterSuply';
import { selectSUB } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { selectDirection } from '~/src/store/directionSlice';
import { createCn } from '~/src/helpers';
import { selectCV } from '~/src/store/cottage.village/cottage.village.store';

const SearchHiddenForm = ({
    show,
    formFilter,
    setFormFilter,
}: THiddenSearchFormProps): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { search } = useSelector(selectSUB);
    const { me } = useSelector(selectAuth);
    const { xmlFeeds } = useSelector(selectAgency);
    const { unloadItems } = useSelector(selectDirection);
    const { unloadCVItems } = useSelector(selectCV);

    const directionData = unloadItems.map((item) => {
        return {
            label: item.title,
            value: item.id.toString(),
        };
    });

    const cottageVillageData = unloadCVItems.map((item) => {
        return {
            label: item.title,
            value: item.id.toString(),
        };
    });

    const [showMore, setShowMore] = useState<boolean>(false);

    const agents = me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
        return {
            value: uid,
            label: fullname,
        };
    });

    const onSelectChange = (
        field: string,
        o: { label: string; value: string },
    ) => {
        setFormFilter({ ...formFilter, [field]: o?.value ?? null });
    };
    const handleChangeValue = (e: BaseSyntheticEvent) => {
        setFormFilter({ ...formFilter, [e.target.name]: e.target.value });
    };

    const handleChangeMultiSelect = (
        items: MultiValue<any>,
        name: string
    ) => {
        const entity = [];
        items.forEach((item, index) => {
            entity.push(String(item.value));
        });
        setFormFilter({
            ...formFilter,
            [name]: entity,
        });
    };

    // const changeSelectSerch = (option, inputValue) => {
    //     const normalizedOptionLabel = option.label.toLowerCase().replace('е', 'ё');
    //     const normalizedInputValue = inputValue.toLowerCase().replace('е', 'ё');
    //     return normalizedOptionLabel.includes(normalizedInputValue);
    // };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        setFormFilter({
            ...formFilter,
            [e.target.name]: formFilter[e.target.name] == '1' ? '' : '1',
        });
    };
    const handleChangeValueNumberValidator = (e: BaseSyntheticEvent) => {
        setFormFilter({
            ...formFilter,
            [e.target.name]: e.target.value.replaceAll(/\D/g, ''),
        });
    };

    const handleChangeCNValidator = (e: BaseSyntheticEvent) => {
        setFormFilter({
            ...formFilter,
            [e.target.name]: createCn(e.target.value),
        });
    };

    const handleChangePrice = (e: BaseSyntheticEvent) => {
        let value;
        if (e.target.value.length > 0) {
            value = e.target.value.replaceAll(/\D/g, '');
            value = new Intl.NumberFormat('ru-RU').format(value);
        } else {
            value = e.target.value;
        }

        setFormFilter({ ...formFilter, [e.target.name]: value });
    };

    const handleShowMoreFilter = () => {
        setShowMore(!showMore);
    };

    useEffect(() => {
        setFormFilter(search);
    }, [query.toString(), search]);

    console.log(cottageVillageData.filter(
        (r) => formFilter.cottageVillageId?.includes(r.value)
    ));

    if (show) {
        return (
            <>
                {me && me.agencyInfo ? (
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Агенты</strong>
                                </Form.Label>
                                <Select
                                    name='agentUid'
                                    options={agents}
                                    value={agents.filter(
                                        (r) => formFilter.agentUid == r.value,
                                    )}
                                    onChange={(o) =>
                                        onSelectChange('agentUid', o)
                                    }
                                    isClearable
                                    placeholder='Агент'
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Группы</strong>
                                </Form.Label>
                                <Form.Select
                                    onChange={handleChangeValue}
                                    size='sm'
                                    name='agencyGroupId'
                                    value={formFilter.agencyGroupId || 0}
                                >
                                    {me.agencyInfo?.agencyGroups?.map(
                                        ({ title, id }, index) => {
                                            if (index === 0) {
                                                return (
                                                    <React.Fragment key={id}>
                                                        <option value=''></option>
                                                        <option
                                                            key={id}
                                                            value={`${id}`}
                                                        >
                                                            {title}
                                                        </option>
                                                    </React.Fragment>
                                                );
                                            } else return (
                                                <option
                                                    key={id}
                                                    value={`${id}`}
                                                >
                                                    {title}
                                                </option>);
                                        },
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label>
                                    <strong>ID</strong>
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='id'
                                    value={formFilter?.id || ''}
                                    onChange={handleChangeValue}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                ) : null}
                {me && me.agencyInfo && (
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Выгружается</strong>
                                </Form.Label>
                                <Form.Select
                                    onChange={handleChangeValue}
                                    size='sm'
                                    name='xmlFeedId'
                                    value={formFilter.xmlFeedId}
                                >
                                    {xmlFeeds?.map(({ title, id }, index) => {
                                        if (index === 0) {
                                            return (
                                                <React.Fragment key={id}>
                                                    <option value=''></option>
                                                    <option value={`${id}`}>
                                                        {title}
                                                    </option>
                                                </React.Fragment>
                                            );
                                        } else return (
                                            <option
                                                key={id}
                                                value={`${id}`}
                                            >
                                                {title}
                                            </option>);
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    <strong>Тракт</strong>
                                </Form.Label>
                                <Select
                                    name='directionId'
                                    options={directionData}
                                    isMulti
                                    value={directionData.filter((r) => formFilter.directionId?.includes(r.value))}
                                    onChange={(item) => handleChangeMultiSelect(item, 'directionId')}
                                    isClearable
                                    placeholder='Тракт'
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label>
                                    <strong>Кадастровый номер</strong>
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='cn'
                                    value={formFilter?.cn || ''}
                                    onChange={handleChangeCNValidator}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={6}>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Цена</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='priceMin'
                                        value={formFilter?.priceMin || ''}
                                        onChange={handleChangePrice}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='priceMax'
                                        value={formFilter?.priceMax || ''}
                                        onChange={handleChangePrice}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col xs={6}>
                        <Form.Label>
                            <strong>Ремонт</strong>
                        </Form.Label>
                        <Select
                            name='renovation'
                            options={suburbanRenovationSearch}
                            value={suburbanRenovationSearch.filter(
                                (r) => formFilter.renovation == r.value,
                            )}
                            onChange={(o) => onSelectChange('renovation', o)}
                            isClearable
                            placeholder='Ремонт'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                <strong>Площадь участка</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='spaceTotalMin'
                                        value={formFilter?.spaceTotalMin || ''}
                                        onChange={handleChangeValue}
                                        placeholder='от'
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        name='spaceTotalMax'
                                        value={formFilter?.spaceTotalMax || ''}
                                        onChange={handleChangeValue}
                                        placeholder='до'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                {<strong>Площадь дома</strong>}
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseSpaceTotalMin'
                                    value={formFilter?.houseSpaceTotalMin || ''}
                                    onChange={handleChangeValueNumberValidator}
                                    placeholder='от'
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseSpaceTotalMax'
                                    value={formFilter?.houseSpaceTotalMax || ''}
                                    onChange={handleChangeValueNumberValidator}
                                    placeholder='до'
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='d-flex align-items-end'>
                    <Col>
                        <FormGroup>
                            <Form.Label>
                                {<strong>Количество спальных комнат</strong>}
                            </Form.Label>
                            <InputGroup className='flex gap-4'>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseRoomsMin'
                                    value={formFilter?.houseRoomsMin || ''}
                                    onChange={handleChangeValueNumberValidator}
                                    placeholder='от'
                                />
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    name='houseRoomsMax'
                                    value={formFilter?.houseRoomsMax || ''}
                                    onChange={handleChangeValueNumberValidator}
                                    placeholder='до'
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Form.Label>
                                {<strong>Коттеджный поселок</strong>}
                            </Form.Label>
                            <Select
                                name='cottageVillageId'
                                options={cottageVillageData}
                                isMulti
                                // value={cottageVillageData.filter(
                                //     (r) => formFilter.cottageVillageId?.includes(r.value)
                                // )}
                                onChange={(item) => handleChangeMultiSelect(item, 'cottageVillageId')}
                                isClearable
                                placeholder='КП'
                            />
                        </FormGroup>

                    </Col>
                </Row>
                {showMore ? (
                    <React.Fragment>
                        <Row>
                            <Col>
                                <Form.Label>
                                    <strong>Отопление</strong>
                                </Form.Label>
                                <Select
                                    name='heating'
                                    options={suburbanHeatingSearch}
                                    value={suburbanHeatingSearch.filter(
                                        (r) => formFilter.heating == r.value,
                                    )}
                                    onChange={(o) =>
                                        onSelectChange('heating', o)
                                    }
                                    isClearable
                                    placeholder='Отопление'
                                />
                            </Col>
                            <Col>
                                <Form.Label>
                                    <strong>Материал дома</strong>
                                </Form.Label>
                                <Select
                                    name='houseMaterial'
                                    options={suburbanMaterialSearch}
                                    value={suburbanMaterialSearch.filter(
                                        (r) =>
                                            formFilter.houseMaterial == r.value,
                                    )}
                                    onChange={(o) =>
                                        onSelectChange('houseMaterial', o)
                                    }
                                    isClearable
                                    placeholder='Материал дома'
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col>
                                <Form.Label>
                                    <strong>Водоснабжение</strong>
                                </Form.Label>
                                <Select
                                    name='waterSuply'
                                    options={suburbanWaterSuplySearch}
                                    value={suburbanWaterSuplySearch.filter(
                                        (r) => formFilter.waterSuply == r.value,
                                    )}
                                    onChange={(o) =>
                                        onSelectChange('waterSuply', o)
                                    }
                                    isClearable
                                    placeholder='Водоснабжение'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='bathhouse'
                                    onChange={handleChangeCheckboxValue}
                                    label='Баня'
                                    checked={formFilter.bathhouse == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='garage'
                                    onChange={handleChangeCheckboxValue}
                                    label='Гараж'
                                    checked={formFilter.garage == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='сarport'
                                    onChange={handleChangeCheckboxValue}
                                    label='Навес для машины'
                                    checked={formFilter.сarport == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='septic'
                                    onChange={handleChangeCheckboxValue}
                                    label='Септик'
                                    checked={formFilter.septic == '1'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='isFenced'
                                    onChange={handleChangeCheckboxValue}
                                    label='Участок огорожен'
                                    checked={formFilter.isFenced == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='isClosedArea'
                                    onChange={handleChangeCheckboxValue}
                                    label='Закрытая территория'
                                    checked={formFilter.isClosedArea == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='isProtectedArea'
                                    onChange={handleChangeCheckboxValue}
                                    label='Охраняемая территория'
                                    checked={formFilter.isProtectedArea == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='isAreaSportGround'
                                    onChange={handleChangeCheckboxValue}
                                    label='Спортивная площадка'
                                    checked={
                                        formFilter.isAreaSportGround == '1'
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='isSchoolBeside'
                                    onChange={handleChangeCheckboxValue}
                                    label='Рядом школа'
                                    checked={formFilter.isSchoolBeside == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='isPreschoolBeside'
                                    onChange={handleChangeCheckboxValue}
                                    label='Рядом д. сад'
                                    checked={
                                        formFilter.isPreschoolBeside == '1'
                                    }
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='hasBesideWater'
                                    onChange={handleChangeCheckboxValue}
                                    label='Рядом водоем'
                                    checked={formFilter.hasBesideWater == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='hasBesideForest'
                                    onChange={handleChangeCheckboxValue}
                                    label='Рядом лес'
                                    checked={formFilter.hasBesideForest == '1'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox
                                    name='hasBesidePark'
                                    onChange={handleChangeCheckboxValue}
                                    label='Рядом парк'
                                    checked={formFilter.hasBesidePark == '1'}
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='withoutBankEncumbrance'
                                    onChange={handleChangeCheckboxValue}
                                    label='Без обременения банком'
                                    checked={
                                        formFilter.withoutBankEncumbrance == '1'
                                    }
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='withoutUnderstatement'
                                    onChange={handleChangeCheckboxValue}
                                    label='Без занижения'
                                    checked={
                                        formFilter.withoutUnderstatement == '1'
                                    }
                                />
                            </Col>
                            <Col>
                                <Checkbox
                                    name='terrace'
                                    onChange={handleChangeCheckboxValue}
                                    label='Терраса'
                                    checked={formFilter.terrace == '1'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                        </Row>
                    </React.Fragment>
                ) : null}
                <ButtonGroup>
                    <Button
                        className='mt-2'
                        type='submit'
                        variant='primary'
                        size='sm'
                    >
                        Найти
                    </Button>
                    <Button
                        variant='link'
                        size='sm'
                        onClick={handleShowMoreFilter}
                    >
                        {showMore
                            ? 'Скрыть подробный поиск'
                            : 'Подробный поиск'}
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return null;
};

type THiddenSearchFormProps = {
    show: boolean;
    formFilter: any;
    setFormFilter: React.Dispatch<React.SetStateAction<any>>;
    toggleShowForm: () => void;
};

export default SearchHiddenForm;
