import { IResponseListData } from '../interfaces/common';
import Api from '~/src/helpers/http-common';
import { IHousingEstateHousePorchStoreyFlat } from './housing.estate.house.porch.storey.flat';
import { IRSModel } from '../store/realtySecondary/realtySecondarySlice';
import { IMegasaleAddRealty, IMegaSaleRealty } from '../store/megasaleSlice';
import { IRSUBModel } from '../store/realtySuburban/realtySuburbanSlice';

export const checkMegasale = async (): Promise<any> => {
    return await Api.get('/megasale/get-active');
};

export const createMegasale = async (
    data: ICreateMegasale,
): Promise<ICreateMegasale | any> => {
    return await Api.post('/megasale', data);
};

export const addRealtyMegasale = async (
    data: IAddRealtyMegasale,
): Promise<IMegasaleAddRealty> => {
    return await Api.post<IMegasaleAddRealty>('/megasale/realty/add', data);
};

export const removeRealtyMegasale = async (
    id: number | string,
): Promise<any> => {
    return await Api.post(`/megasale/realty/${id}/remove`);
};

export const verifyRealtyMegasale = async (
    id: number | string,
): Promise<any> => {
    return await Api.post(`/megasale/realty/${id}/verify`);
};

export const allMegasaleRealty = async (queryParams?: URLSearchParams): Promise<IResponseListData<IAllMegasaleRealty>> => {
    return await Api.get(`/megasale/realty/all?${queryParams != null ? `${queryParams.toString()}&_=${new Date().getTime()}` : ''}`);
};

export interface ICreateMegasale {
    dateStart: Date;
    dateFinish: Date;
    note: string;
}

export interface IAddRealtyMegasale {
    realtyType: string;
    realtyId: number;
    price: number;
}

export interface IAllMegasaleRealty extends IMegaSaleRealty {
    realty: IRSModel | IRSUBModel | IHousingEstateHousePorchStoreyFlat
}
